import {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import {Paper, Typography, Select, MenuItem, FormControl, InputLabel, Grid, Alert, AlertTitle} from '@mui/material';
import {getHousePeriodicConsumptions} from "../../../api_calls/api_calls_houses";
import Loading from "../../layout/Loading";

function HouseMonitoringPeriodicConsumptions() {
    const {id} = useParams();
    const [period, setPeriod] = useState('lastday');
    const [labels, setLabels] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [dataError, setDataError] = useState(false)

    const handleChange = (event) => {
        setPeriod(event.target.value);
    };

    useEffect(() => {
        setLoading(true);
        setDataError(false);
        getHousePeriodicConsumptions(period, id)
            .then(response => {
                setLoading(false);
                // Divide each value in the consumption array by 1000
                // const adjustedConsumption = response.consumption.map(value => value / 1000);
                // setData(adjustedConsumption);
                setData(response.consumption)
                setLabels(response.labels);
            })
            .catch(error => {
                setLoading(false);
                setDataError(true);
                console.log(error);
            });
    }, [period, id]);


    // Calculate the average
    const average = data.length ? data.reduce((acc, val) => acc + val, 0) / data.length : 0;
    const color = {
        low: '#00ABE1', // Light blue
        middle: '#4A78A6', // Mid color between light blue and dark blue
        high: '#161F6D' // Dark blue
    };

    const getGradientColor = (value) => {
        const max = average + 0.25 * average;
        if (value < average) {
            return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {offset: 0, color: color.low},
                {offset: 1, color: color.middle}
            ]);
        } else if (value <= max) {
            return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {offset: 0, color: color.middle},
                {offset: 1, color: color.high}
            ]);
        } else {
            return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {offset: 0, color: color.high},
                {offset: 1, color: color.high}
            ]);
        }
    };

    // Determine axis labels
    const xAxisLabel = period === 'lastday' ? 'Hours of the Day' : 'Day';
    const yAxisLabel = 'Consumption (kWh)';

    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            data: ['Consumption (kWh)'],
            formatter: function () {
                return xAxisLabel;
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: labels,
                axisTick: {
                    alignWithLabel: true
                },
                name: xAxisLabel // Set dynamic x-axis label
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: yAxisLabel // Set static y-axis label
            }
        ],
        series: [
            {
                name: 'Consumption (kWh)',
                type: 'bar',
                barWidth: '60%',
                data: data,
                itemStyle: {
                    color: function (params) {
                        return getGradientColor(params.value);
                    }
                }
            },
            // Uncomment to include average line
            // {
            //     name: 'Average',
            //     type: 'line',
            //     data: new Array(labels.length).fill(average),
            //     lineStyle: {
            //         color: '#FF5722', // Color for the average line
            //         type: 'dashed', // Dashed line style
            //     },
            //     label: {
            //         show: true,
            //         position: 'end',
            //         formatter: `Average: ${average.toFixed(2)}`, // Display average value
            //     }
            // }
        ]
    };

    return (
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'stretch', mt: 1, mb: 5 }}>
            <Grid item xs={12} md={9} sx={{ display: 'flex', flexDirection: 'column', order: { xs: 1, md: 2 } }}>
                <Paper
                    elevation={0}
                    style={{
                        padding: '20px',
                        borderRadius: '25px',
                        backgroundColor: 'white',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        // marginBottom: '16px'
                    }}>
                        <Typography variant='h5'>Periodic Consumption</Typography>
                        <FormControl variant="outlined" style={{ minWidth: 120 }}>
                            <InputLabel>Period</InputLabel>
                            <Select
                                value={period}
                                onChange={handleChange}
                                label="Period"
                            >
                                <MenuItem value='lastday'>Last Day</MenuItem>
                                <MenuItem value='lastweek'>Last Week</MenuItem>
                                <MenuItem value='lastmonth'>Last Month</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ flex: 1, width: '100%' }}>
                        {loading && <Loading />}
                        {!loading && dataError &&
                            <Alert severity="error" sx={{mt: 2}}>
                                <AlertTitle>Error</AlertTitle>
                                Something went wrong while fetching the data. Please try again later.
                            </Alert>
                        }
                        {!loading && !dataError && <ReactECharts option={option} style={{ width: '100%' }} />}
                    </div>
                </Paper>
            </Grid>

            <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column', order: { xs: 2, md: 1 } }}>
                <Paper
                    elevation={0}
                    style={{
                        padding: '20px',
                        borderRadius: '25px',
                        backgroundColor: 'white',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    {loading && <div style={{ margin: 'auto' }}><Loading /></div>}
                    {!loading && dataError &&
                        <Alert severity="error" sx={{mt: 2}}>
                            <AlertTitle>Error</AlertTitle>
                            Something went wrong while fetching the data. Please try again later.
                        </Alert>
                    }
                    {!loading && !dataError && <>
                        <div style={{ textAlign: 'center', marginTop: 'auto' }}>
                            <Typography className={'fancyText'} variant={'h1'} fontWeight={600}>
                                {average.toFixed(2)}
                            </Typography>
                            <Typography variant="subtitle1" align={'center'} sx={{ color: '#666666', marginTop: '0px' }}>
                                <span style={{ fontWeight: 'bold' }} className={'fancyTextSmall'}>(kWh)</span>
                            </Typography>
                        </div>

                        <Typography variant="subtitle2" align={'center'} sx={{ color: '#666666', marginTop: 'auto' }}>
                            {period === 'lastday'
                                ? 'Average consumption of the day (hourly)'
                                : period === 'lastweek'
                                    ? 'Average consumption of the week (daily)'
                                    : 'Average consumption of the month (daily)'}
                        </Typography>
                    </>}
                </Paper>
            </Grid>
        </Grid>
    );
}

export default HouseMonitoringPeriodicConsumptions;
