import React, {useState, useEffect} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend, BarElement,
} from 'chart.js';
import {Line, Bar} from 'react-chartjs-2';

import {
    Accordion, AccordionDetails, AccordionSummary,
    Box, Typography, ToggleButtonGroup, Alert,
    ToggleButton, Grid, TextField, Button, Container, AlertTitle,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
    getShellyConsumptionsDaily, getShellyConsumptionsMonthly, getShellyConsumptionsWeekly,
    getShellyConsumptionYearly, getShellyConsumptionsChosenPeriod
} from "../../api_calls/api_calls_shelly";

import Loading from "../layout/Loading";


const ShellyCharts = ({shellyId, token}) => {
    const [loadingCharts, setLoadingCharts] = useState(false)
    const [errorCharts, setErrorCharts] = useState(false)
    const [chartData, setChartData] = useState([33, 2, 3, 7, 3, 6, 78])
    const [period, setPeriod] = useState('daily')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [labels, setLabels] = useState([])

    useEffect(() => {
        setErrorCharts(false)
        setChartData([])

        period !== 'choice' && setLoadingCharts(true)

        if (period === 'daily') {
            getShellyConsumptionsDaily(shellyId, period)
                .then(response => {
                    setLabels(response.labels)
                    setChartData(response.values)
                    setLoadingCharts(false)
                })
                .catch(error => {
                    setLoadingCharts(false)
                    setErrorCharts(true)
                })
        }

        if (period === 'weekly') {
            getShellyConsumptionsWeekly(shellyId)
                .then(response => {
                    setLabels(response.labels)
                    setChartData(response.values)
                    setLoadingCharts(false)
                })
                .catch(error => {
                    setLoadingCharts(false)
                    setErrorCharts(true)
                })
        }

        if (period === 'monthly') {
            getShellyConsumptionsMonthly(shellyId)
                .then(response => {
                    setLabels(response.labels)
                    setChartData(response.values)
                    setLoadingCharts(false)
                })
                .catch(error => {
                    setLoadingCharts(false)
                    setErrorCharts(true)
                })
        }

        if (period === 'yearly') {
            setLoadingCharts(true)
            getShellyConsumptionYearly(shellyId)
                .then(response => {
                    setLabels(response.labels)
                    setChartData(response.values)
                    setLoadingCharts(false)
                })
                .catch(error => {
                    setLoadingCharts(false)
                    setErrorCharts(true)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period])

    const handleChosenDates = () => {
        setLoadingCharts(true)
        getShellyConsumptionsChosenPeriod(shellyId, startDate, endDate)
            .then(response => {
                setLabels(response.labels)
                setChartData(response.values)
                setLoadingCharts(false)
            })
            .catch(error => {
                setLoadingCharts(false)
                setErrorCharts(true)
            })
    }

    const handleChange = (event, newPeriod) => {
        setPeriod(newPeriod);

        if (newPeriod === 'choice') {
            setStartDate('')
            setEndDate('')
        }
    };

    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, BarElement);

    const label =
        period === 'daily' ? 'Consumption per hour' :
            period === 'weekly' ? 'Consumption per day (week)' :
                period === 'monthly' ? 'Consumption per day (month)' :
                    period === 'yearly' ? 'Consumption per year' :
                        period === 'choice' ? 'Selected period' : ''

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Shelly Periodic Consumption charts',
            },
        },
    };
    const data = {
        labels,
        datasets: [
            {
                label: period !== 'choice' ? label + ' (kWh)' : label,
                data: chartData,
                borderColor: 'rgb(22, 31, 109)',
                backgroundColor: 'rgba(22, 31, 109, 0.5)',
            },
        ],
    };

    return (
        <>
            <Accordion sx={{borderRadius: '5px'}} disableGutters defaultExpanded>
                <AccordionSummary className={'fancyBackground'}
                                  sx={{borderTopRightRadius: '5px', borderTopLeftRadius: '5px'}}
                                  expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                >
                    <Box>
                        <Typography variant="h6" sx={{color: '#fff'}}>
                            Periodic Consumption Charts
                        </Typography>
                        <Typography variant={'subtitle2'} sx={{color: '#fff', fontWeight: 'normal'}}>
                            Monitor your device's energy consumption on hourly, daily, weekly, monthly and yearly basis.
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {loadingCharts && <Container sx={{my: 5, py: 5}}><Loading color={'#161F6D'}/></Container>}
                    {!loadingCharts && !errorCharts && period !== 'yearly' &&
                        <div style={{height: '50vh', width: '100%'}}><Line options={options} data={data}/></div>}
                    {!loadingCharts && period === 'yearly' &&
                        <div style={{height: '50vh', width: '100%'}}><Bar options={options} data={data}/></div>}

                    {errorCharts && <Alert severity="error">
                        <AlertTitle>Oops, something went wrong!</AlertTitle>
                        Please try again later.
                    </Alert>}

                    {period === 'choice' &&
                        <Grid container my={2} justifyContent={'center'} alignItems={'center'} spacing={3}>
                            <Grid item>
                                <TextField
                                    id="date"
                                    label="Από"
                                    type="date"
                                    value={startDate}
                                    onChange={e => setStartDate(e.target.value)}
                                    sx={{width: 220}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id="date"
                                    label="Until"
                                    type="date"
                                    value={endDate}
                                    onChange={e => setEndDate(e.target.value)}
                                    sx={{width: 220}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={handleChosenDates}
                                        sx={{background: 'linear-gradient(to right, #161F6D, #00ABE1)'}}>Select</Button>
                            </Grid>
                        </Grid>}

                    <Box display="flex"
                         justifyContent="center"
                         alignItems="center"
                         sx={{my: 2}}>
                        <ToggleButtonGroup
                            color="primary"
                            value={period}
                            exclusive
                            disabled={loadingCharts}
                            onChange={handleChange}>
                            <ToggleButton value="daily"><Typography variant={'subtitle2'}>Per
                                Hour</Typography></ToggleButton>
                            <ToggleButton value="weekly"><Typography variant={'subtitle2'}>Per Day
                                (Week)</Typography></ToggleButton>
                            <ToggleButton value="monthly"><Typography variant={'subtitle2'}>Per Day (Month)</Typography></ToggleButton>
                            <ToggleButton value="yearly"><Typography variant={'subtitle2'}>Per
                                Year</Typography></ToggleButton>
                            <ToggleButton value="choice"><Typography variant={'subtitle2'}>Choose
                                dates</Typography></ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
}

export default ShellyCharts;