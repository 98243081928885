// Functions that could be useful in many components
export const range = (start, end) => {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

export const assignColorsToValues = numbers => {
    const average = numbers.reduce((sum, num) => sum + num, 0) / numbers.length;
    const threshold = 0.25 * average;
    const secondThreshold = 0.45 * average

    return numbers.map((num) => {
        if (num <= average) {
            return "#008DB8";
        } else if (num <= average + threshold) {
            return "#00689D";
        } else if (num >= average + threshold && num <= average + secondThreshold) {
            return "#0A4E8F";
        } else {
            return "#004C90";
        }
    });
}

export const disaggregationProperties = device => {
    if (device === 'Air-Conditioning') return 'linear-gradient(45deg, rgba(0,171,225,1) 0%, rgba(22,31,109,1) 50%, rgba(0,171,225,1) 100%)'
    if (device === 'Boiler') return 'linear-gradient(45deg, rgba(98,161,219,1) 0%, rgba(180,69,31,1) 50%, rgba(98,161,219,1) 100%);'
    if (device === 'Fridge') return 'linear-gradient(45deg, rgba(229,229,229,1) 0%, rgba(0,171,225,1) 50%, rgba(229,229,229,1) 100%)'
    if (device === 'Oven') return 'linear-gradient(45deg, rgba(245,84,14,1) 0%, rgba(123,24,24,1) 50%, rgba(245,84,14,1) 100%)'
    if (device === 'Lighting') return 'linear-gradient(45deg, rgba(255,244,140,1) 0%, rgba(216,174,0,1) 50%, rgba(255,244,140,1) 100%)'
    if (device === 'Washing Machine') return 'linear-gradient(45deg, rgba(186,185,50,1) 0%, rgba(102,140,29,1) 50%, rgba(186,185,50,1) 100%);'
    if (device === 'Plate Washing Machine') return 'linear-gradient(45deg, rgba(117,181,190,1) 0%, rgba(0,134,139,1) 50%, rgba(117,181,190,1) 100%);'
    if (device === 'Other devices') return 'linear-gradient(45deg, rgba(204,204,204,1) 0%, rgba(154,154,154,1) 50%, rgba(204,204,204,1) 100%);'
}