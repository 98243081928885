import React, {useEffect, useState} from 'react';
// import {v4 as uuidv4} from 'uuid';
import {useParams} from "react-router-dom";
import {
    shellyCreation,
    // shellysGetAllHouse
} from '../../api_calls/api_calls_shelly';

import {
    Box, Button, Container, Divider,
    FormControl, Grid, InputLabel,
    MenuItem, Select, Stack, Alert,
    TextField, FormHelperText,
    Typography, Paper, Snackbar
} from "@mui/material";

import PageTitle from "../../components/layout/PageTitle";
import LoadingFullPage from "../../components/layout/LoadingFullPage";
// import ShellyListTable from "../../components/admin/ShellyListTable";

const ShellyRegistration = () => {
    const {houseId} = useParams()

    useEffect(() => {
        document.title = 'Add Shelly Device | Energy Home System'
    }, [])

    // Device information variables
    const [deviceName, setDeviceName] = useState('')
    const [deviceId, setDeviceId] = useState('')
    const [isEm, setIsEm] = useState('')
    const [emType, setEmType] = useState('')
    const [ip, setIp] = useState('')

    // Device information error handling variables
    const [deviceNameError, setDeviceNameError] = useState(false)
    const [deviceIdError, setDeviceIdError] = useState(false)
    const [isEmError, setIsEmError] = useState(false)
    const [emTypeError, setEmTypeError] = useState(false)

    // Variable for backdrop
    const [backDrop, setBackDrop] = useState(false)

    // Notification state
    const [notification, setNotification] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    // const [loading, setLoading] = useState(false)
    // const [shellys, setShellys] = useState([])
    // const fetchAllShellys = () => {
    //     setLoading(true)
    //     shellysGetAllHouse(houseId)
    //         .then(response => {
    //             setShellys(response)
    //             setLoading(false)
    //         })
    //         .catch(error => {
    //             setLoading(false)
    //         })
    // }

    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormSubmitted(true);

        // Validate fields
        const nameError = deviceName === ''
        const idError = deviceId === ''
        const isEmFieldError = isEm === ''
        const typeError = isEm === 'true' && emType === ''

        setDeviceNameError(nameError)
        setDeviceIdError(idError)
        setIsEmError(isEmFieldError)
        setEmTypeError(typeError)

        if (!nameError && !idError && !isEmFieldError && !typeError) {
            // setBackDrop(true)
            const payload = {
                name: deviceName,
                device_id: deviceId,
                is_em: isEm === 'true',
                em_type: emType,
                houses_table_id: houseId,
            };

            if (ip && ip.trim() !== '') {
                payload.ip = ip;
            }

            console.log(payload)

            shellyCreation(payload)
                .then(() => {
                    // fetchAllShellys()
                    setBackDrop(false)
                    setNotification({
                        open: true,
                        message: 'The device has been successfully added!',
                        severity: 'success'
                    })
                })
                .catch(error => {
                    setBackDrop(false)
                    setNotification({
                        open: true,
                        message: 'Something went wrong! Please try again.',
                        severity: 'error'
                    })
                    console.log(error)
                })
        }
    }

    const handleCloseSnackbar = () => {
        setDeviceName('')
        setDeviceId('')
        setIsEm('')
        setEmType('')
        setIp('')
        setNotification({open: false, message: '', severity: 'success'})
        setFormSubmitted(false)
    }

    useEffect(() => {
        if (formSubmitted) {
            setDeviceNameError(deviceName === '')
            setDeviceIdError(deviceId === '')
            setIsEmError(isEm === '')
            setEmTypeError(isEm === 'true' && emType === '')
        }
    }, [deviceName, deviceId, isEm, emType, formSubmitted]);

    return (
        <>
            <PageTitle title="Add Shelly Device"/>

            <Container sx={{mt: 5}} maxWidth={'xl'}>
                <Paper sx={{p: 3, mt: 5}}>
                    <Typography variant="h4" sx={{color: 'middle.main', pb: 0, pt: 3}}>Add Shelly Device</Typography>
                    <Divider sx={{backgroundColor: 'middle.main', height: '3px', mb: 4}}/>
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField fullWidth required id="device-name" label="Device Name"
                                           variant="outlined"
                                           helperText={'Please enter the name you want to assign to the device'}
                                           value={deviceName}
                                           placeholder="Insert device name"
                                           error={deviceNameError}
                                           onChange={e => setDeviceName(e.target.value)}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField fullWidth required id="device-id" label="Device ID"
                                           variant="outlined"
                                           helperText={'Please enter the device ID'}
                                           value={deviceId}
                                           placeholder="Insert device ID"
                                           error={deviceIdError}
                                           onChange={e => setDeviceId(e.target.value)}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl required fullWidth error={isEmError}>
                                    <InputLabel id="is-em-label">Is EM</InputLabel>
                                    <Select
                                        labelId="is-em-label"
                                        id="is-em"
                                        value={isEm}
                                        label="Is EM"
                                        onChange={e => setIsEm(e.target.value)}
                                    >
                                        <MenuItem value={'true'}>True</MenuItem>
                                        <MenuItem value={'false'}>False</MenuItem>
                                    </Select>
                                    <FormHelperText>Please choose if the device is EM</FormHelperText>
                                </FormControl>
                            </Grid>
                            {isEm === 'true' && (
                                <Grid item xs={12} md={6}>
                                    <FormControl required fullWidth error={emTypeError}>
                                        <InputLabel id="em-type-label">EM Type</InputLabel>
                                        <Select
                                            labelId="em-type-label"
                                            id="em-type"
                                            value={emType}
                                            label="EM Type"
                                            onChange={e => setEmType(e.target.value)}
                                        >
                                            <MenuItem value={'EM'}>EM</MenuItem>
                                            <MenuItem value={'3EM'}>3EM</MenuItem>
                                        </Select>
                                        <FormHelperText>Please choose the type of EM</FormHelperText>
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item xs={12} md={6}>
                                <TextField fullWidth id="device-ip" label="Device IP"
                                           variant="outlined"
                                           helperText={'Please enter the IP of the device (optional)'}
                                           value={ip}
                                           placeholder="Insert device IP"
                                           onChange={e => setIp(e.target.value)}/>
                            </Grid>
                        </Grid>
                        <Grid sx={{marginX: 'auto'}} item xs={12} md={3}>
                            <Box sx={{mt: 3, mb: 2}}>
                                <Button type="submit" fullWidth variant="contained" color="middle">
                                    <Typography variant="small" sx={{color: 'white'}}>ADD SHELLY DEVICE</Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </form>
                </Paper>
            </Container>

            {/*<ShellyListTable loading={loading} shellys={shellys} fetchAllShellys={fetchAllShellys}/>*/}

            <LoadingFullPage backDrop={backDrop}/>
            <Stack spacing={2} sx={{width: '100%'}}>
                <Snackbar open={notification.open} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} variant={"filled"} severity={notification.severity}
                           sx={{width: '100%'}}>
                        {notification.message}
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    );
}

export default ShellyRegistration;
