import React from 'react';
import HomepageHeroSection from "../components/homepage/HomepageHeroSection";
import HomepageFeaturesSection from "../components/homepage/HomepageFeaturesSection";
import HomepageLastSection from "../components/homepage/HomepageLastSection";

const Homepage_new = () => {
    return (
        <>
            <HomepageHeroSection/>
            <HomepageFeaturesSection/>
            <HomepageLastSection/>
        </>
    );
}

export default Homepage_new;
