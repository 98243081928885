import React, {useEffect} from 'react';

import HomepageFullWidthItem from "../components/HomepageFullWidthItem";
import {functionalityListHomepage} from "../functionalityListHomepage";

const Homepage = () => {
    useEffect(() => {
        document.title = 'Homepage | Energy Home System'
    }, [])


    return (
        <div style={{marginBottom: '-24px'}}>
            {functionalityListHomepage.map((service, index) => (
                <HomepageFullWidthItem title={service.title} description={service.description} icon={service.icon}
                                       image={service.image} link={service.link} index={index}
                                       key={service.id}/>))}
        </div>);
}

export default Homepage;