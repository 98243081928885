import React, {useState, useEffect, useRef} from 'react';
// import {useSwitch} from '@mui/core/SwitchUnstyled';

import {useParams} from "react-router-dom";
import {acExecuteFunction, acGetEntityScenarios, acScheduling} from "../../api_calls/api_calls_appliance";
import PageTitle from "../../components/layout/PageTitle";

import {Grid} from "@mui/material";

import {acControllerGetEntity} from "../../api_calls/api_calls_broadlinks";
import AirConditionScheduling from "../../components/appliances/AirConditionScheduling";
import AirConditionController from "../../components/appliances/AirConditionController";

const AirConditionHandling = () => {
    let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRob3JpemVkIjp0cnVlLCJleHAiOjE3MTY1NTA2OTIsInVzZXJfaWQiOiIxZjZiNWZjNy1jNmEzLTQyMzctOGQzZS0yZjc3MzIxNTQyNjYifQ.PdkP_Tjyhy-vYSmwZSF96mMoloUtkOAiiCzeqLGbZ4E'
    const {id} = useParams();
    const [appliance, setAppliance] = useState({});

    const [scenarios, setScenarios] = useState([])
    const [scenariosLoading, setScenariosLoading] = useState(false)

    const [schedulingStartLoading, setSchedulingStartLoading] = useState(false)
    const [schedulingEndLoading, setSchedulingEndLoading] = useState(false)

    const getACScenarios = () => {
        setScenariosLoading(true)
        acGetEntityScenarios(id)
            .then(response => {
                setScenarios(response)
                setScenariosLoading(false)
            })
            .catch(error => {
                console.log(error)
                setScenariosLoading(false)
            })
    }

    useEffect(() => {
        acControllerGetEntity(id)
            .then(response => {
                document.title = `${response.name} Handling & Scheduling | Energy Home System`
                setAppliance(response)
            })
            .catch(error => console.log(error))

        getACScenarios()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [isHeat, setIsHeat] = useState(false)
    const [temperature, setTemperature] = useState(25)

    const [valueON, setValueON] = useState(null);
    const [valueOFF, setValueOFF] = useState(null);

    const tempField = useRef(null)

    // TODO If A/C is open, also send signal with new temperature
    const increaseTemperature = () => {
        if (temperature < 30) {
            setTemperature(temperature + 1)
            tempField.current.classList.add('downUp')
            setTimeout(() => {
                tempField.current.classList.remove('downUp')
            }, 200)
        }
    }

    // TODO If A/C is open, also send signal with new temperature
    const decreaseTemperature = () => {
        if (temperature > 17) {
            setTemperature(temperature - 1)
            tempField.current.classList.add('upDown')
            setTimeout(() => {
                tempField.current.classList.remove('upDown')
            }, 200)
        }
    }

    const handleChangeON = (newValue) => {
        setValueON(newValue);
    };

    const handleChangeOFF = (newValue) => {
        setValueOFF(newValue);
    };

    const handleExecutionTempHeat = () => {
        let func = {
            isHeat: isHeat ? '1' : '0',
            accontrollerid: id,
            temp: temperature.toString()
        }

        acExecuteFunction(func)
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleExecutionOnOff = () => {
        let func = {
            isHeat: '',
            accontrollerid: id,
            temp: '',
            name: 'OFF'
        }

        acExecuteFunction(func)
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleExecutionScheduling = () => {
        valueON !== '' ? setSchedulingStartLoading(true) : setSchedulingEndLoading(true)
        let payload = {
            days: '',
            descr: 'once',
            endtime: valueOFF,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            isHeat: isHeat ? '1' : '0',
            id,
            months: '',
            name: '',
            starttime: valueON,
            temp: temperature.toString(),
            typeofdevice: 'ac',
            typeofjob: ''
        }

        acScheduling(payload)
            .then(response => {
                getACScenarios()
                valueON !== '' ? setSchedulingStartLoading(false) : setSchedulingEndLoading(false)
                setValueON(null)
                setValueOFF(null)
            })
            .catch(error => {
                valueON !== '' ? setSchedulingStartLoading(false) : setSchedulingEndLoading(false)
                setValueON(null)
                setValueOFF(null)
                console.log(error)
            })
    }

    return (
        <React.Fragment>
            <PageTitle title={appliance.name + ' Handling & Scheduling'}/>
            <Grid container mt={0} px={2}
                  direction="row" spacing={2}
                  alignItems="stretch">
                <Grid item xs={12} md={4}>
                    <AirConditionController
                        appliance={appliance}
                        handleExecutionOnOff={handleExecutionOnOff}
                        isHeat={isHeat}
                        setIsHeat={setIsHeat}
                        increaseTemperature={increaseTemperature}
                        decreaseTemperature={decreaseTemperature}
                        tempField={tempField}
                        temperature={temperature}
                        valueON={valueON}
                        handleChangeON={handleChangeON}
                        valueOFF={valueOFF}
                        handleChangeOFF={handleChangeOFF}
                        handleExecutionScheduling={handleExecutionScheduling}
                        handleExecutionTempHeat={handleExecutionTempHeat}
                        schedulingStartLoading={schedulingStartLoading}
                        schedulingEndLoading={schedulingEndLoading}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <AirConditionScheduling scenarios={scenarios} scenariosLoading={scenariosLoading} getACScenarios={getACScenarios}/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default AirConditionHandling;