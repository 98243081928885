import axios from 'axios'

// Get all broadlinks
export const broadlinksGetAll = houseId => {
    return new Promise(((resolve, reject) => {
        axios.get(`/broadlinks/listed/${houseId}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Get specific A/C controller
export const acControllerGetEntity = id => {
    return new Promise(((resolve, reject) => {
        axios.get(`/ac/info/${id}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Delete broadlink
export const broadlinkDelete = id => {
    return new Promise(((resolve, reject) => {
        axios.delete(`broadlinks/delete/${id}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Add broadlink for a house
export const broadlinkAddForHouse = (mac, port, ip, devtype, name, house_table_id) => {
    let payload = {
        mac, port, ip, devtype, name, house_table_id
    }
    return new Promise((resolve, reject) => {
        axios.post('/broadlinks/create/', payload)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    })
}

// Delete broadlink from house
// TODO This REST API call did not have an access token
export const broadlinkDeleteFromRoom = id => {
    return new Promise((resolve, reject) => {
        axios.delete(`/ac/delete/${id}/`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    })
}