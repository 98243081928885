import {getAllHouses} from "../api_calls/api_calls_houses";
import useHousesContext from "./useHousesContext";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import React from "react";

export const useRefreshHouses = () => {
    const {dispatch} = useHousesContext()

    const refreshHouses = () => {
        getAllHouses()
            .then(response => {
                let housesUpd =  [{
                    text: 'All',
                    icon: <HomeOutlinedIcon color="primary"/>,
                    whiteIcon: <HomeOutlinedIcon sx={{color: 'white'}}/>,
                    path: '/rooms'
                }]
                for (let i = 0; i < response.length; i++) {
                    housesUpd.push({
                        text: response[i].address,
                        icon: <HomeOutlinedIcon color="primary"/>,
                        whiteIcon: <HomeOutlinedIcon sx={{color: 'white'}}/>,
                        path: `/rooms/house/${response[i].id}`
                    })
                }

                dispatch({
                    type: 'REFRESH_HOUSES',
                    payload: housesUpd
                })
            })
            .catch(error => console.log('error'))
    }

    return {refreshHouses}
}