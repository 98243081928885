import React, {useState, useEffect} from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {getAllRooms, deleteRoomEntity, getAllHouseRooms} from "../../api_calls/api_calls_rooms";
import {useParams} from "react-router-dom";
import {Grid, Snackbar, Stack, Alert, Container} from "@mui/material";
import RoomCard from "../../components/rooms/RoomCard";
import NoRooms from "../../components/rooms/NoRooms";
import LoadingRoomCard from "../../components/rooms/LoadingRoomCard";
import PageTitle from "../../components/layout/PageTitle";
import FloatingActionButton from "../../components/layout/FloatingActionButton";
import LoadingFullPage from "../../components/layout/LoadingFullPage";
import FetchRoomsFail from "../../components/rooms/FetchRoomsFail";

const MyRooms = () => {
    useEffect(() => {
        document.title = 'Rooms | Energy Home System'
    }, [])

    const {keycloak, initialized} = useKeycloak();
    const [allowed, setAllowed] = useState(false);

    useEffect(() => {
        if (initialized) {
            if (!keycloak.authenticated) {
                keycloak.login();
            } else {
                setAllowed(true);
            }
        }
    }, [keycloak, initialized]);

    let {id} = useParams()
    useEffect(() => {
        setRooms([])
        setFetchError(false)
    }, [id])

    const [rooms, setRooms] = useState([])
    const [zeroRooms, setZeroRooms] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [fetchError, setFetchError] = useState(false);

    // Variable for backdrop
    const [backDrop, setBackDrop] = useState(false)

    // Notification state
    const [notification, setNotification] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const handleNotificationClose = () => {
        setNotification({...notification, open: false});
    };

    const deleteRoom = roomId => {
        setBackDrop(true)
        deleteRoomEntity(roomId)
            .then(() => {
                setNotification({
                    open: true,
                    message: 'The room has been successfully deleted!',
                    severity: 'success'
                });
                getAllRooms()
                    .then(response => {
                        response === null ? setZeroRooms(true) : setRooms(response)
                        setBackDrop(false)
                    })
                    .catch(() => {
                        setNotification({
                            open: true,
                            message: 'Something went wrong while fetching rooms! Please try again.',
                            severity: 'error'
                        });
                        setBackDrop(false)
                    })
            })
            .catch(() => {
                setNotification({
                    open: true,
                    message: 'Something went wrong! Please try again.',
                    severity: 'error'
                });
                setBackDrop(false)
            })
    }

    useEffect(() => {
        setLoading(true)
        setLoaded(false)
        const fetchRooms = id ? getAllHouseRooms : getAllRooms;

        fetchRooms(id)
            .then(response => {
                response === null ? setZeroRooms(true) : setRooms(response)
                setLoading(false)
                setLoaded(true)
            })
            .catch(() => {
                setLoading(false);
                setLoaded(true);
                setFetchError(true);
                setNotification({
                    open: true,
                    message: 'Something went wrong while fetching rooms! Please try again.',
                    severity: 'error'
                });
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return (
        <>
            <PageTitle title="Rooms"/>
            {allowed && <>
                {loading &&
                    <Container maxWidth="xl">
                        <Grid container rowSpacing={2} spacing={2} sx={{mt: 3, px: {xs: 1, md: 5}}}>
                            {[...Array(2)].map(() => {
                                return (
                                    <Grid item xs={12} md={6} lg={4} key={Math.floor(Math.random() * 100)}>
                                        <LoadingRoomCard/>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Container>}
                {zeroRooms && loaded && <NoRooms/>}
                {fetchError && loaded && <FetchRoomsFail/>}
                {!zeroRooms && loaded &&
                    <Container maxWidth="xl">
                        <Grid container rowSpacing={2} spacing={2} sx={{mt: 3, px: {xs: 1, md: 5}}}>
                            {rooms.map(room => (
                                <Grid item xs={12} md={6} lg={4} key={room.id}>
                                    <RoomCard room={room}
                                        deleteRoom={deleteRoom}
                                        // roomAppliances={{
                                        //     broadlinks: room.accontrollers || [],
                                        //     shellys: room.shellys || []
                                        // }}

                                        // sensorData={room.sensor}
                                        // houseId={1}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Container>}
                <FloatingActionButton link={'/add/room'} tooltip={'Add Room'}/>
                <LoadingFullPage backDrop={backDrop} setBackDrop={setBackDrop}/>
                <Stack spacing={2} sx={{width: '100%'}}>
                    <Snackbar
                        open={notification.open}
                        onClose={handleNotificationClose}
                        autoHideDuration={3000}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    >
                        <Alert
                            variant="filled"
                            onClose={handleNotificationClose}
                            severity={notification.severity}
                        >
                            {notification.message}
                        </Alert>
                    </Snackbar>
                </Stack>
            </>}
        </>
    );
}

export default MyRooms;
