import React, {createContext, useReducer} from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

export const HousesContext = createContext()

export const houseReducer = (state, action) => {
    switch (action.type) {
        case 'REFRESH_HOUSES':
            return {
                housesUpd: action.payload
            }
        default:
            return state
    }
}

export const HousesContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(houseReducer, {
        housesUpd: [{
            text: 'All',
            icon: <HomeOutlinedIcon color="primary"/>,
            whiteIcon: <HomeOutlinedIcon sx={{color: 'white'}}/>,
            path: '/rooms'
        }]
    })

    // useEffect(() => {
    //     const housesUpd = localStorage.getItem('housesUpd')
    // }, [])

    return (
        <HousesContext.Provider value={{...state, dispatch}}>
            {children}
        </HousesContext.Provider>
    )
}

