import {useEffect, useState} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web"
import {getHouseEntity} from "../../api_calls/api_calls_houses";

import {Container, Grid, Snackbar, Alert, AlertTitle} from "@mui/material";

import PageTitle from "../../components/layout/PageTitle";
import HouseMonitoringWelcomeMsg from "../../components/houses/house reports/HouseMonitoringWelcomeMsg";
import HouseMonitoringLatestMetrics from "../../components/houses/house reports/HouseMonitoringLatestMetrics";
import HouseMonitoringConsumptionFeed from "../../components/houses/house reports/HouseMonitoringConsumptionFeed";
import HouseMonitoringPeriodicConsumptions
    from "../../components/houses/house reports/HouseMonitoringPeriodicConsumptions";

function HouseReportsNew() {
    const {id} = useParams();
    const navigate = useNavigate();
    const {keycloak, initialized} = useKeycloak();
    const [allowed, setAllowed] = useState(false);
    const [houseIdError, setHouseIdError] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {
        if (initialized) {
            if (!keycloak.authenticated) {
                keycloak.login();
            } else {
                setAllowed(true);
            }
        }
    }, [keycloak, initialized]);

    // Get the information about the house
    const [houseAddress, setHouseAddress] = useState('');
    useEffect(() => {
        if (initialized === true) {
            getHouseEntity(id)
                .then(response => {
                    setHouseAddress(response.address);
                })
                .catch(error => {
                    setHouseIdError(true);
                    setOpenSnackbar(true);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized]);

    useEffect(() => {
        document.title = 'Energy Consumption Monitoring | Energy Home System';
    }, []);

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
        navigate('/houses');
    };

    return (
        <>
            <PageTitle title="House Monitoring / Temperature & Humidity"/>

            {allowed && houseAddress && (
                <Container sx={{mt: 5}} maxWidth={'xl'}>
                    <Grid container spacing={2} sx={{display: 'flex', alignItems: 'stretch', my: 1}}>
                        <Grid item xs={12} md={6} sx={{display: 'flex', flexDirection: 'column'}}>
                            <HouseMonitoringWelcomeMsg houseAddress={houseAddress} style={{flexGrow: 1}}/>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{display: 'flex', flexDirection: 'column'}}>
                            <HouseMonitoringLatestMetrics style={{flexGrow: 1}}/>
                        </Grid>
                    </Grid>

                    <HouseMonitoringConsumptionFeed/>

                    <HouseMonitoringPeriodicConsumptions/>
                </Container>
            )}

            {allowed && houseIdError &&
                <Container sx={{mt: 5}}>
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        This house does not exist. You will be navigated to the houses page.
                    </Alert>
                </Container>
            }

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            >
                <Alert onClose={handleSnackbarClose} severity="error" variant={'filled'} sx={{width: '100%'}}>
                    This house ID is not valid.
                </Alert>
            </Snackbar>
        </>
    );
}

export default HouseReportsNew;
