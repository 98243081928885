import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, Skeleton, Alert, AlertTitle } from '@mui/material';
import { useParams } from 'react-router-dom';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import TodayIcon from '@mui/icons-material/Today';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { getHouseLatestMetrics } from '../../../api_calls/api_calls_houses';

const HouseMonitoringLatestMetrics = () => {
    const { id } = useParams();

    // State to manage metrics, loading status, and error state
    const [metrics, setMetrics] = useState({
        lastHourConsumption: '',
        last24HoursConsumption: '',
        yesterdayConsumption: '',
        todayConsumption: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        setLoading(true);
        setError(false);
        getHouseLatestMetrics(id)
            .then(response => {
                setMetrics({
                    lastHourConsumption: `${response.last_hour_consumption} kWh`,
                    last24HoursConsumption: `${response.last_24_hours_consumption} kWh`,
                    yesterdayConsumption: `${response.yesterdays_consumption} kWh`,
                    todayConsumption: `${response.todays_consumption} kWh`
                });
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching metrics:', error);
                setError(true);
                setLoading(false);
            });
    }, [id]); // Dependency array includes `id` so it refetches when `id` changes

    const boxStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '25px',
        width: '100%',
        gap: '10px',
    };

    return (
        <Grid container spacing={2}>
            {error ? (
                <Grid item xs={12}>
                    <Paper elevation={0} style={boxStyle}>
                        <Alert severity="error" sx={{ mt: 2 }}>
                            <AlertTitle>Error</AlertTitle>
                            Something went wrong while fetching the data. Please try again later.
                        </Alert>
                    </Paper>
                </Grid>
            ) : (
                <>
                    <Grid item xs={12} md={6}>
                        {loading ? (
                            <Skeleton variant="rectangular" width='100%' height='100%' sx={{ borderRadius: '25px' }} />
                        ) : (
                            <Paper elevation={0} style={boxStyle}>
                                <AccessAlarmIcon style={{ fontSize: 40, color: '#0A6BAC' }} />
                                <Box>
                                    <Typography variant="h6">{metrics.lastHourConsumption}</Typography>
                                    <Typography variant="subtitle2">Last hour consumption</Typography>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {loading ? (
                            <Skeleton variant="rectangular" width='100%' height={80} sx={{ borderRadius: '25px' }} />
                        ) : (
                            <Paper elevation={0} style={boxStyle}>
                                <EventAvailableIcon style={{ fontSize: 40, color: '#0A6BAC' }} />
                                <Box>
                                    <Typography variant="h6">{metrics.last24HoursConsumption}</Typography>
                                    <Typography variant="subtitle2">Last 24 hours consumption</Typography>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {loading ? (
                            <Skeleton variant="rectangular" width='100%' height={80} sx={{ borderRadius: '25px' }} />
                        ) : (
                            <Paper elevation={0} style={boxStyle}>
                                <TodayIcon style={{ fontSize: 40, color: '#0A6BAC' }} />
                                <Box>
                                    <Typography variant="h6">{metrics.yesterdayConsumption}</Typography>
                                    <Typography variant="subtitle2">Yesterday consumption</Typography>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {loading ? (
                            <Skeleton variant="rectangular" width='100%' height={80} sx={{ borderRadius: '25px' }} />
                        ) : (
                            <Paper elevation={0} style={boxStyle}>
                                <EventNoteIcon style={{ fontSize: 40, color: '#0A6BAC' }} />
                                <Box>
                                    <Typography variant="h6">{metrics.todayConsumption}</Typography>
                                    <Typography variant="subtitle2">Consumption today until now</Typography>
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default HouseMonitoringLatestMetrics;
