import {Container, Grid, Paper, Typography} from '@mui/material';
import {features} from "./features";
import {useTheme} from "@mui/material/styles";

const HomepageFeaturesSection = () => {
    const theme = useTheme();

    return (
        <Container maxWidth="xl" sx={{mb: 5}}>
            <Typography
                className={'fancyTextSmall'}
                variant="h2"
                gutterBottom
                sx={{
                    fontWeight: 500,
                    width: {
                        xs: '100%',  // 100% width on extra-small screens (mobile)
                        md: '55%'    // 55% width on small screens and up (tablet and larger)
                    },
                }}
            >
                Features to Manage, Control, and Optimize with Ease
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                {features.map((feature, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Paper
                            elevation={0}
                            style={{
                                padding: '40px',
                                borderRadius: '25px',
                                backgroundColor: 'white',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            {feature.icon}
                            <Typography variant="h4" gutterBottom sx={{color: theme.palette.secondary.main, mt: 2}}>
                                {feature.title}
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                                {feature.description}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default HomepageFeaturesSection;
