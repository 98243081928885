import React from 'react';
import {Link} from "react-router-dom";

import {Typography, Button, Container, Grid, Paper, Box} from "@mui/material";

const ZeroHouses = () => {
    return (
        <div>
            <Container>
                <Paper sx={{p: 8, mt: 5}}>
                    <Grid container justifyContent='center'>
                        <Typography variant='h3'>Welcome to Energy Home System!</Typography>
                        <Typography variant='h4' sx={{mt: 2}}>
                            A smart tool to help you control your devices.
                        </Typography>
                        <Typography variant='h6' sx={{mt: 6}}>
                            Get started by adding some important information about your house.
                        </Typography>
                    </Grid>

                    <Grid container justifyContent='center'>
                        <Box sx={{mt: 3, mb: 2}}>
                            <Link to="/add/house" style={{textDecoration: 'none'}}>
                                <Button style={{color: 'white'}} type="submit" fullWidth variant="contained"
                                        color="secondary" onClick={null}>
                                    <Typography variant='small'>ADD HOUSE</Typography>
                                </Button>
                            </Link>
                        </Box>
                    </Grid>
                </Paper>
            </Container>
        </div>
    );
}

export default ZeroHouses;