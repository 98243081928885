import React from 'react';
import {Button, Grid, Paper, Typography, Container} from "@mui/material";
import {NavLink} from "react-router-dom";

function HomepageHeroSection() {
    return (
        <Container maxWidth={'xl'} sx={{my: 5}}>
            <Paper
                elevation={0}
                style={{
                    paddingRight: '40px',
                    paddingLeft: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    borderRadius: '25px',
                    backgroundColor: 'white',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Typography variant="h2" gutterBottom className={'fancyTextSmall'} fontWeight={'500'}>
                            Take Control of Your Home’s Energy Today
                        </Typography>
                        <Button
                            variant="contained"
                            className="customButton"
                            component={NavLink}
                            to="/signup"
                            sx={{textTransform: 'none', paddingX: '50px'}}
                        >
                            Get Started
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} className="imageContainer">
                        <img src="/images/homepage_hero.png" alt="Energy Control" className="responsiveImage"/>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default HomepageHeroSection;
