import axios from 'axios'

// Air Condition learning
export const acLearning = ac => {
    return new Promise(((resolve, reject) => {
        axios.post('ac/learn', ac)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Air Condition get learning modes
export const acLearningModes = id => {
    return new Promise(((resolve, reject) => {
        axios.get(`/ac/islearn/${id}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Air Condition execute function
export const acExecuteFunction = payload => {
    return new Promise(((resolve, reject) => {
        setTimeout(() => {
            axios.post('/ac/execute/now', payload)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => reject(error))
        }, 2000)
    }))
}

// Air Condition delete function
export const acDeleteFunction = payload => {
    return new Promise(((resolve, reject) => {
        axios.delete('/ac/command/delete', {data: payload})
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Air Condition scheduling
export const acScheduling = (payload, token) => {
    return new Promise(((resolve, reject) => {
        axios.post('/job/create', payload)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Air Condition delete all learnt functions
export const acDeleteLearntFunctions = id => {
    return new Promise(((resolve, reject) => {
        axios.delete(`/ac/remove/commands/${id}`, )
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Air Conditions - Get all scheduled scenarios
export const acGetAllScenarios = () => {
    return new Promise(((resolve, reject) => {
        axios.get('/job/ac/all')
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Air Conditions - Delete specific scheduled scenario by tag
export const acDeleteScheduledScenario = tag => {
    return new Promise((resolve, reject) => {
        axios.delete(`/job/remove/${tag}`)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

// Air Conditions - Get all scheduled scenarios for specific AC
export const acGetEntityScenarios = id => {
    return new Promise(((resolve, reject) => {
        axios.get(`/job/ac/${id}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Air Conditions - Delete specific scheduled scenario by tag
export const acEntityDeleteScheduledScenario = tag => {
    return new Promise((resolve, reject) => {
        axios.delete(`/job/remove/${tag}`)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

// Shelly - Get current status
export const shellyGetCurrentStatus = id => {
    return new Promise(((resolve, reject) => {
        axios.get(`/shelly/status/${id}`)
            .then(response => resolve(response.data))
            .catch(error => {
                console.log('this is error')
                reject(error)
            })
    }))
}

// Shelly - Toggle ON/OFF
export const shellyToggleOnOff = (shellyId, state) => {
    return new Promise(((resolve, reject) => {
        axios.post(`/shelly/state/${shellyId}/${state}`, null)
            .then(response => resolve(response))
            .catch(error => reject(error))
    }))
}

// Shellys - Get all scheduled scenarios
export const shellyGetAllScenarios = () => {
    return new Promise(((resolve, reject) => {
        axios.get('/job/shelly/all')
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Shelly - Delete specific scheduled scenario by tag
export const shellyDeleteScheduledScenario = tag => {
    return new Promise((resolve, reject) => {
        axios.delete(`/job/remove/${tag}`)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

// Shellys - Get all scheduled scenarios for specific AC
export const shellyGetEntityScenarios = id => {
    return new Promise(((resolve, reject) => {
        axios.get(`/job/shelly/${id}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Air Condition scheduling
export const shellyScheduling = payload => {
    return new Promise(((resolve, reject) => {
        axios.post('/job/create', payload)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}