// App.js

import React, { useEffect } from 'react';
import {
    Route,
    BrowserRouter as Router,
    Routes,
} from 'react-router-dom';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';

import Homepage from './pages/Homepage';
import Layout from './components/layout/Layout';
import MyHouses from './pages/houses/MyHouses';
import AddHouse from './pages/houses/HouseForm';
import BroadlinkRegistration from './pages/appliances/BroadlinkRegistration';
import ShellyRegistration from './pages/appliances/ShellyRegistration';
import MyRooms from './pages/rooms/MyRooms';
import AddRoom from './pages/rooms/AddRoom';
import AirConditionsDashboard from './pages/appliances/AirConditionsDashboard';
import ShellysDashboard from './pages/appliances/ShellysDashboard';
import ShellyHandling from './pages/appliances/ShellyHandling';
import AirConditionLearning from './pages/appliances/AirConditionLearning';
import AirConditionHandling from './pages/appliances/AirConditionHandling';
import SignUp from './pages/auth/SignUp';
import HouseReports_new from "./pages/houses/HouseReports_new";
import Homepage_new from "./pages/Homepage_new";

// Custom hook to remove specific URL parameters
const useRemoveUrlParams = () => {
    useEffect(() => {
        const removeUrlParams = () => {
            // Get the current URL
            const url = new URL(window.location.href);

            // Check if the URL has an 'iss' parameter in the hash
            if (url.hash.includes('iss=')) {
                // Remove 'iss' parameter from the hash
                const updatedHash = url.hash.replace(/(\?|&)iss=[^&]*(&|$)/, '$1').replace(/^#&/, '#');

                // Replace the current URL with updated hash in the browser history
                window.history.replaceState(null, '', updatedHash);
            }
        };

        removeUrlParams(); // Call the function on component mount
    }, []);
};

function App() {
    const { keycloak } = useKeycloak();

    // Call the custom hook to remove URL parameters
    useRemoveUrlParams();

    // Configure Axios defaults
    axios.defaults.baseURL = 'https://hedgeiot.epu.ntua.gr/api';
    axios.defaults.headers = {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': keycloak.idToken,
    };

    console.log(keycloak.idToken);

    return (
        <Router>
            <Routes>
                <Route path='/' element={<Layout />}>
                    <Route index element={<Homepage />} />
                    <Route path='homepage' element={<Homepage_new />} />

                    {/* Houses */}
                    <Route path='houses' element={<MyHouses />} />
                    <Route path='add/house' element={<AddHouse />} />
                    <Route path='edit/house/:id' element={<AddHouse />} />
                    <Route path='/house/id/:id/reports' element={<HouseReports_new />} />

                    {/* Rooms */}
                    <Route path='rooms' element={<MyRooms />} />
                    <Route path='rooms/house/:id' element={<MyRooms />} />
                    <Route path='add/room' element={<AddRoom />} />

                    {/* Devices */}
                    <Route path='broadlink/add/house/id/:houseId' element={<BroadlinkRegistration />} />
                    <Route path='shelly/add/house/id/:houseId' element={<ShellyRegistration />} />
                    <Route path='/airconditions/dashboard' element={<AirConditionsDashboard />} />
                    <Route path='/shellys/dashboard' element={<ShellysDashboard />} />

                    <Route path='/controller/aircondition/learning/:id' element={<AirConditionLearning />} />
                    <Route path='/controller/aircondition/handling/:id' element={<AirConditionHandling />} />

                    <Route path='/controller/shelly/handling/:id' element={<ShellyHandling />} />

                    <Route path='/signup' element={<SignUp />} />
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
