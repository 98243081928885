import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {tableCellClasses} from "@mui/material/TableCell";
import {red} from "@mui/material/colors";

import {
    Box, Button, Typography, Accordion,
    AlertTitle, AccordionDetails, AccordionSummary,
    Snackbar, Alert, Paper, Grid, Select,
    FormControl, MenuItem, Stack, InputLabel,
    OutlinedInput, Checkbox, ListItemText, TextField,
    Container, TableContainer, Table, TableHead,
    TableRow, TableCell, TableBody, Tooltip
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';

import {
    shellyDeleteScheduledScenario, shellyScheduling

} from "../../api_calls/api_calls_appliance";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import LoadingFullPage from "../layout/LoadingFullPage";
import Loading from "../layout/Loading";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const dayList = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];

const CustomAlert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const ShellyScheduling = ({scenarios, scenariosLoading, getShellyScenarios}) => {
    const {id} = useParams();

    // Form fields to be sent
    const [days, setDays] = useState([]);
    const [valueON, setValueON] = useState(null);
    const [valueOFF, setValueOFF] = useState(null);

    // Error handlers
    const [daysError, setDaysError] = useState(false);
    const [valueONError, setValueONError] = useState(false);
    const [valueOFFError, setValueOFFError] = useState(false);


    // Variable for backdrop
    const [backDrop, setBackDrop] = useState(false)

    // Variable for snackbar
    const [success, setSuccess] = useState(false)
    const [failure, setFailure] = useState(false)

    useEffect(() => {
        getShellyScenarios()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDays = (event) => {
        const {
            target: {value},
        } = event;
        setDays(
            // On autofill we get the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeON = newValue => {
        setValueON(newValue);
    };
    const handleChangeOFF = newValue => {
        setValueOFF(newValue);
    };

    const handleSubmit = e => {
        e.preventDefault()

        // Error handling
        days.length === 0 ? setDaysError(true) : setDaysError(false)
        if (valueON === null && valueOFF === null) {
            setValueONError(true)
            setValueOFFError(true)
        } else {
            setValueONError(false)
            setValueOFFError(false)
        }

        // Request to API
        if (days.length > 0 && (valueON !== null || valueOFF !== null)) {
            setBackDrop(true)
            let payload = {
                id,
                days,
                descr: 'schedule',
                startTime: valueON,
                endtime: valueOFF,
                typeofdevice: 'shelly',
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }

            shellyScheduling(payload)
                .then(response => {
                    getShellyScenarios()
                    setBackDrop(false)
                    setSuccess(true)
                    setDays([])
                    setValueON(null)
                    setValueOFF(null)
                })
                .catch(error => {
                    setBackDrop(false)
                    setFailure(true)
                })
        }
    }

    const handleScenarioDelete = tag => {
        shellyDeleteScheduledScenario(tag)
            .then(response => {
                getShellyScenarios()
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <Grid sx={{display: 'flex', flexDirection: 'column', mt: 2}}>
            <Accordion sx={{borderRadius: '5px', py: 0, mt: 2}} defaultExpanded={true} expanded={true}
                       disableGutters>
                <AccordionSummary className={'fancyBackground noCursorPointer'}
                                  sx={{borderTopRightRadius: '5px', borderTopLeftRadius: '5px'}}
                                  aria-controls="panel2bh-content"
                                  id="panel2bh-header">
                    <Box>
                        <Typography variant="h6" sx={{color: '#fff'}}>
                            Add scenario
                        </Typography>
                        <Typography variant={'subtitle2'} sx={{color: '#fff', fontWeight: 'normal'}}>
                            Set a scenario for your device to turn ON/OFF on scheduled days and hours.
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container spacing={2} display={'flex'} justifyContent={'center'} sx={{py: 2}}>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth required error={days.length === 0 && daysError}>
                                    <InputLabel id="demo-multiple-checkbox-label">Day(s)</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={days}
                                        onChange={handleDays}
                                        input={<OutlinedInput label="Day(s)"/>}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {dayList.map(day => (
                                            <MenuItem key={day} value={day}>
                                                <Checkbox checked={days.indexOf(day) > -1}/>
                                                <ListItemText primary={day}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl required={valueOFF === null} fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            label="Turn on at"
                                            value={valueON}
                                            onChange={handleChangeON}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl required={valueON === null} fullWidth
                                             error={valueOFFError && valueON === null}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            label="Turn off at"
                                            value={valueOFF}
                                            onChange={handleChangeOFF}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {valueONError && valueOFFError &&
                            <Box mt={2}>
                                <CustomAlert severity="error">
                                    You must select at least one field from "Turn on at" and "Turn off at"!
                                </CustomAlert>
                            </Box>}

                        <Grid sx={{marginX: 'auto'}} item xs={12} md={2} mt={'auto'} display={'flex'}
                              justifyContent={'center'}>
                            <Box sx={{mt: 5}}>
                                <Button type="submit" fullWidth variant="contained" color="secondary">
                                    <Typography variant="small" sx={{color: 'white'}}>SCHEDULE</Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{borderRadius: '5px', mt: 2}} disableGutters defaultExpanded>
                <AccordionSummary className={'fancyBackground'}
                                  sx={{borderTopRightRadius: '5px', borderTopLeftRadius: '5px'}}
                                  expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                >
                    <Box>
                        <Typography variant="h6" sx={{color: '#fff'}}>
                            Scheduled scenarios
                        </Typography>
                        <Typography variant={'subtitle2'} sx={{color: '#fff', fontWeight: 'normal'}}>A list of the
                            scheduled scenarios for this device.
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {scenariosLoading && <Container sx={{py: 5}}><Loading/></Container>}
                    {scenarios.length > 0 &&
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align={'center'}><Typography
                                                variant={'body1'} sx={{fontWeight: 'bold'}}>House /
                                                Room</Typography></StyledTableCell>
                                            <StyledTableCell align={'center'}><Typography
                                                variant={'body1'}
                                                sx={{fontWeight: 'bold'}}>Function</Typography></StyledTableCell>
                                            <StyledTableCell align={'center'}><Typography
                                                variant={'body1'}
                                                sx={{fontWeight: 'bold'}}>Day(s)</Typography></StyledTableCell>
                                            <StyledTableCell align={'center'}><Typography
                                                variant={'body1'} sx={{fontWeight: 'bold'}}>Turn
                                                on</Typography></StyledTableCell>
                                            <StyledTableCell align={'center'}><Typography
                                                variant={'body1'} sx={{fontWeight: 'bold'}}>Turn
                                                of</Typography></StyledTableCell>
                                            <StyledTableCell align={'center'}><Typography
                                                variant={'body1'}
                                                sx={{fontWeight: 'bold'}}>Delete</Typography></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    {scenarios.map(scenario => (
                                        <TableBody key={scenario.tag}>
                                            <TableCell
                                                align={'center'}><Typography>{scenario.housename} / {scenario.roomname}</Typography></TableCell>
                                            <TableCell
                                                align={'center'}><Typography>on/off
                                                indicator</Typography></TableCell>
                                            <TableCell
                                                align={'center'}><Typography>{scenario.days.join(', ')}</Typography></TableCell>
                                            <TableCell
                                                align={'center'}><Typography>{scenario.starttime}</Typography></TableCell>
                                            <TableCell
                                                align={'center'}><Typography>{scenario.endtime}</Typography></TableCell>
                                            <TableCell align={'center'}>
                                                <Tooltip title="Delete">
                                                    <IconButton onClick={() => handleScenarioDelete(scenario.tag)}>
                                                        <DeleteOutlineIcon
                                                            sx={{color: red[500], fontSize: '25px'}}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableBody>
                                    ))}
                                </Table>
                            </TableContainer>
                        </Grid>
                    }
                    {scenarios.length < 1 && !scenariosLoading && <Alert severity="error" sx={{mt: 1}}>
                        <AlertTitle>No scheduled scenarios!</AlertTitle>
                        Schedule your device and come back to see the list of scheduled scenarios!
                    </Alert>}
                </AccordionDetails>
            </Accordion>

            <LoadingFullPage backDrop={backDrop} setBackDrop={setBackDrop}/>

            <Stack spacing={2} sx={{width: '100%'}}>
                {/* TODO handle close with content refresh */}
                <Snackbar open={success} autoHideDuration={3000} onClose={() => setSuccess(false)}>
                    <CustomAlert onClose={() => setSuccess(false)} severity="success">
                        The scenario has been successfully scheduled!
                    </CustomAlert>
                </Snackbar>
                {/* TODO handle close with content refresh */}
                <Snackbar open={failure} autoHideDuration={3000} onClose={() => setFailure(false)}>
                    <CustomAlert onClose={() => setFailure(false)} severity="error">
                        Something went wrong! Please try again.
                    </CustomAlert>
                </Snackbar>
            </Stack>
        </Grid>
    );
}

export default ShellyScheduling;