import axios from 'axios'

// Shelly creation
export const shellyCreation = payload => {
    return new Promise(((resolve, reject) => {
        axios.post(`/device/create`, payload)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Delete shelly from house
// TODO This REST API call did not have an access token
export const shellyDeleteFromRoom = id => {
    return new Promise((resolve, reject) => {
        axios.delete(`/shelly/delete/${id}/`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    })
}

// Get Shelly consumption (YEAR)
export const getShellyConsumptionYearly = shellyId => {
    const data = {
        date: new Date(), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    return new Promise((resolve, reject) => {
        axios.post(`/sensors/shelly/yearly/shelly/electricity/sum/${shellyId}`, data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    })
}

// Get Shelly consumptions (Last 24h)
export const getShellyConsumptionsDaily = shellyId => {
    const data = {
        date: new Date(), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    return new Promise((resolve, reject) => {
        axios.post(`/sensors/shelly/daily/electricity/hourly/${shellyId}`, data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    })
}

// Get Shelly consumptions (Last 7d)
export const getShellyConsumptionsWeekly = shellyId => {
    const data = {
        date: new Date(), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    return new Promise((resolve, reject) => {
        axios.post(`/sensors/shelly/weekly/electricity/daily/${shellyId}`, data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    })
}

// Get Shelly consumptions (Last Month)
export const getShellyConsumptionsMonthly = shellyId => {
    const data = {
        date: new Date(), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    return new Promise((resolve, reject) => {
        axios.post(`/sensors/shelly/monthly/electricity/daily/${shellyId}`, data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    })
}

// Get Shelly consumptions for chosen period
export const getShellyConsumptionsChosenPeriod = (shellyId, start_date, end_date) => {
    const data = {start_date, end_date}

    return new Promise((resolve, reject) => {
        axios.post(`/sensors/shelly/dayrange/electricity/daily/${shellyId}`, data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    })
}

// Get all Shellys of a house
export const shellysGetAllHouse = houseId => {
    return new Promise(((resolve, reject) => {
        axios.get(`/shelly/get/home/${houseId}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}

// Delete shelly
export const shellyDelete = id => {
    return new Promise(((resolve, reject) => {
        axios.delete(`shelly/delete/${id}`,)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
    }))
}
