import React from 'react';
import {Link, NavLink, useNavigate} from "react-router-dom";

import {
    Button, Tooltip, Card, CardHeader, Stack,
    CardContent, Typography, Avatar, IconButton, Divider, Grid
} from "@mui/material";

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SettingsIcon from '@mui/icons-material/Settings';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PhotoSizeSelectSmallIcon from '@mui/icons-material/PhotoSizeSelectSmall';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import FlareIcon from '@mui/icons-material/Flare';

import {red} from "@mui/material/colors";

const HouseCard = ({house, deleteHouse}) => {
    const navigate = useNavigate()

    const handleColor = energyClass => {
        if (energyClass.charAt(0) === 'A') return '#9CCC3C'
        if (energyClass.charAt(0) === 'B') return '#FFC114'
        if (energyClass.charAt(0) === 'C' || energyClass.charAt(0) === 'D') return '#F37123'
        else return '#EC2029'
    }

    return (
        <div>
            <Card raised sx={{
                overflow: 'auto'
            }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{bgcolor: 'primary.main', width: 50, height: 50}}>
                            <HomeOutlinedIcon sx={{fontSize: '30px'}}/>
                        </Avatar>}
                    action={
                        <>
                            <Tooltip title="Edit">
                                <NavLink to={`/edit/house/${house.id}`}>
                                    <IconButton>
                                        <SettingsIcon sx={{ color: 'primary.main' }} />
                                    </IconButton>
                                </NavLink>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton onClick={() => deleteHouse(house.id)}>
                                    <DeleteOutlineIcon sx={{ color: red[300] }} />
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                    title={<Typography variant="h5">{house.address}</Typography>}
                    subheader={house.typeHouse}
                />
                <Divider/>
                <CardContent sx={{px: {xs: 0, md: 2}}}>
                    <Grid container alignContent={'center'} justifyContent={'center'} spacing={2}>
                        <Grid item xs={6} md={4} sx={{display: 'flex', flexDirection: 'column'}}>
                            <CalendarMonthIcon className={'houseCardInfoIcon houseCardConstructionYear'}/>
                            <Typography variant={'body1'} sx={{mx: 'auto', color: '#777', fontWeight: 500}}>Construction
                                Year</Typography>
                            <Typography align={'center'} variant={'h6'} sx={{mx: 'auto'}}>{house.year}</Typography>
                        </Grid>
                        <Grid item xs={6} md={4} sx={{display: 'flex', flexDirection: 'column'}}>
                            <PhotoSizeSelectSmallIcon className={'houseCardInfoIcon houseCardConstructionArea'}/>
                            <Typography variant={'body1'} sx={{mx: 'auto', color: '#777', fontWeight: 500}}>Total
                                Area</Typography>
                            <Typography align={'center'} variant={'h6'} sx={{mx: 'auto'}}>{house.area} m²</Typography>
                        </Grid>
                        <Grid item xs={6} md={4} sx={{display: 'flex', flexDirection: 'column'}}>
                            <ApartmentIcon className={'houseCardInfoIcon houseCardConstructionFloor'}/>
                            <Typography variant={'body1'}
                                        sx={{mx: 'auto', color: '#777', fontWeight: 500}}>Floor</Typography>
                            <Typography align={'center'} variant={'h6'} sx={{mx: 'auto'}}>{house.floor}</Typography>
                        </Grid>

                        <Grid item xs={6} md={4} sx={{display: 'flex', flexDirection: 'column'}}>
                            <ElectricBoltIcon className={'houseCardInfoIcon houseCardConstructionEnergyClass'}/>
                            <Typography variant={'body1'} sx={{mx: 'auto', color: '#777', fontWeight: 500}}>Energy
                                Class</Typography>
                            <Typography align={'center'} variant={'h6'} sx={{mx: 'auto'}}
                                        color={() => handleColor(house.energyclass)}>{house.energyclass}</Typography>
                        </Grid>
                        <Grid item xs={6} md={4} sx={{display: 'flex', flexDirection: 'column'}}>
                            <FlareIcon className={'houseCardInfoIcon houseCardConstructionHeating'}/>
                            <Typography variant={'body1'} sx={{mx: 'auto', color: '#777', fontWeight: 500}}>Heating
                                Type</Typography>
                            <Typography align={'center'} variant={'h6'} sx={{mx: 'auto'}}>{house.heatingtype}</Typography>
                        </Grid>
                        <Grid item xs={6} md={4} sx={{display: 'flex', flexDirection: 'column'}}>
                            <AcUnitIcon className={'houseCardInfoIcon houseCardConstructionCooling'}/>
                            <Typography variant={'body1'} sx={{mx: 'auto', color: '#777', fontWeight: 500}}>Cooling
                                Type</Typography>
                            <Typography align={'center'} variant={'h6'} sx={{mx: 'auto'}}>{house.coolingtype}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider/>

                <Stack>
                    <Grid container sx={{minHeight: '45px'}}>
                        <Grid item xs={6} md={3} className={'houseCardActionsFirst'}>
                            <Link to={`/broadlink/add/house/id/${house.id}`} sx={{textDecoration: 'none'}}>
                                <Button variant="text" sx={{width: '100%', height: '100%'}}
                                        startIcon={<ContactlessOutlinedIcon
                                            sx={{color: 'white', borderRadius: '50%'}}/>}>
                                    <Typography color={'white'} variant={'body2'}>Add Broadlink</Typography>
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6} md={3} className={'houseCardActionsSecond'}>
                            <NavLink to={`/shelly/add/house/id/${house.id}`} sx={{textDecoration: 'none'}}>
                                <Button variant="text" sx={{width: '100%', height: '100%'}} startIcon={<ConnectedTvIcon
                                    sx={{color: 'white', borderRadius: '50%'}}/>}>
                                    <Typography color={'white'} variant={'body2'}>Add Shelly</Typography>
                                </Button>
                            </NavLink>
                        </Grid>
                        <Grid item xs={12} md={6} className={'houseCardActionsThird'}>
                            <NavLink to={`/house/id/${house.id}/reports`} sx={{textDecoration: 'none'}}>
                                <Button variant="text" sx={{width: '100%', height: '100%'}}
                                        startIcon={<ShowChartIcon sx={{color: 'white', borderRadius: '50%'}}/>}>
                                    <Typography color={'white'} variant={'body2'}>Energy Consumption
                                        Monitoring</Typography>
                                </Button>
                            </NavLink>
                        </Grid>
                    </Grid>
                </Stack>
            </Card>
        </div>
    );
}

export default HouseCard;