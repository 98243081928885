import React from 'react';
import {Card, CardContent, CardHeader, IconButton, Skeleton} from "@mui/material";

const LoadingHouseCard = () => {
    return (
        <Card>
            <CardHeader
                avatar={<Skeleton animation="wave" variant="circular" width={56} height={56}/>}
                action={
                    <React.Fragment>
                        <IconButton disabled={true}>
                            <Skeleton animation="wave" variant="circular" width={25} height={25}/>
                        </IconButton>
                        <IconButton disabled>
                            <Skeleton animation="wave" variant="circular" width={25} height={25} sx={{mr: 1}}/>
                        </IconButton>
                    </React.Fragment>
                }
                title={<Skeleton animation="wave" height={15} width="80%" style={{marginBottom: 6}}/>}
                subheader={<Skeleton animation="wave" height={15} width="60%"/>}
            />
            <CardContent>
                <Skeleton height={218} sx={{borderRadius: '5px'}} variant="rectangular"/>
            </CardContent>
        </Card>
    );
}

export default LoadingHouseCard;