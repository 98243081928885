import React from 'react';
import Grid from '@mui/material/Grid';
import {Typography} from "@mui/material";

const FooterContent = () => {
    return (
        <Grid container spacing={0} minHeight={'60px'}
              justifyContent="center" height={'100%'}
              alignItems="center" className={'fancyBackground'}>
            <Grid item xs={12} md={4}>
                <Typography fontSize={'small'} align={'center'}>Copyright Energy Home System
                    @{new Date().getFullYear()} All rights reserved</Typography>
            </Grid>

            <Grid item xs={12} md={7}>
                <Typography fontSize={'small'} align={'center'}>
                    Designed to make your home smarter and your life easier, helping you save energy and lower your costs!
                </Typography>
            </Grid>
        </Grid>
    );
}

export default FooterContent;