import {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import ReactECharts from 'echarts-for-react';
import {Paper, Grid, Typography, Alert, AlertTitle} from '@mui/material';
import Loading from "../../layout/Loading";
import {getHouseTodayConsumption} from "../../../api_calls/api_calls_houses";

function HouseMonitoringConsumptionFeed() {
    const {id} = useParams();
    const [labels, setLabels] = useState([]);
    const [data, setData] = useState([]);
    const [average, setAverage] = useState(0);
    const [loading, setLoading] = useState(false)
    const [dataError, setDataError] = useState(false)

    useEffect(() => {
        setLoading(true)
        setDataError(false)

        getHouseTodayConsumption(id)
            .then(response => {
                setLoading(false);
                setLabels(response.labels);
                setData(response.consumption);

                // Calculate the average of the consumption array
                const total = response.consumption.reduce((sum, value) => sum + value, 0);
                const average = total / response.consumption.length;
                setAverage(average);
            })
            .catch(error => {
                setLoading(false);
                setDataError(true);
                console.log(error);
            });
    }, []);

    const option = {
        tooltip: {
            trigger: 'axis', // Trigger the tooltip based on individual data points
            backgroundColor: '#fff', // Background color of the tooltip
            borderColor: '#ccc', // Border color of the tooltip
            borderWidth: 1, // Border width of the tooltip
            padding: 10, // Padding inside the tooltip
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: labels,
        },
        yAxis: {
            type: 'value',
            name: 'Consumption (wh)', // Added y-axis name for clarity
            nameLocation: 'middle',
            nameGap: 30,
            axisLabel: {
                margin: 10,
                fontSize: 12,
            },
        },
        series: [
            {
                data: data,
                type: 'line',
                areaStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: '#161F6D', // Top color
                            },
                            {
                                offset: 1,
                                color: '#00ABE1', // Bottom color
                            },
                        ],
                    },
                },
            },
        ],
        grid: {
            left: '0%',
            right: '0%',
            top: '5%',
            bottom: '5%',
            containLabel: true,
        },
    };

    return (
        <Grid container spacing={2} sx={{display: 'flex', alignItems: 'stretch', my: 1}}>
            <Grid item xs={12} md={9} sx={{display: 'flex', flexDirection: 'column'}}>
                <Paper
                    elevation={0}
                    style={{
                        padding: '20px',
                        borderRadius: '25px',
                        backgroundColor: 'white',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant={'h5'}>Today's Consumption</Typography>
                    <div style={{flex: 1}}>
                        {loading && <div style={{margin: 'auto'}}><Loading/></div>}
                        {!loading && dataError &&
                            <Alert severity="error" sx={{mt: 2}}>
                                <AlertTitle>Error</AlertTitle>
                                Something went wrong while fetching the data. Please try again later.
                            </Alert>
                        }
                        {!loading && !dataError && <ReactECharts option={option} style={{width: '100%'}}/>}
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={12} md={3} sx={{display: 'flex', flexDirection: 'column'}}>
                <Paper
                    elevation={0}
                    style={{
                        padding: '20px',
                        borderRadius: '25px',
                        backgroundColor: 'white',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    {loading && <div style={{margin: 'auto'}}><Loading/></div>}
                    {!loading && dataError &&
                        <Alert severity="error" sx={{mt: 2}}>
                            <AlertTitle>Error</AlertTitle>
                            Something went wrong while fetching the data. Please try again later.
                        </Alert>
                    }
                    {!loading && !dataError &&
                        <>
                            <div style={{textAlign: 'center', marginTop: 'auto'}}>
                                <Typography className={'fancyText'} variant={'h1'} fontWeight={600}>
                                    {average.toFixed(2)}
                                </Typography>
                                <Typography variant="subtitle1" align={'center'}
                                            sx={{color: '#666666', marginTop: '0px'}}>
                                    <span style={{fontWeight: 'bold'}} className={'fancyTextSmall'}>(Wh)</span>
                                </Typography>
                            </div>

                            <Typography variant="subtitle2" align={'center'} sx={{color: '#666666', marginTop: 'auto'}}>
                                Average Today's Consumption
                            </Typography>
                        </>}
                </Paper>
            </Grid>
        </Grid>
    );
}

export default HouseMonitoringConsumptionFeed;
