import React from 'react';
import {ApolloClient, InMemoryCache, ApolloProvider} from '@apollo/client';
import {WebSocketLink} from "@apollo/client/link/ws";
import {SubscriptionClient} from "subscriptions-transport-ws";

import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {createTheme} from '@mui/material/styles';
import {ThemeProvider} from '@mui/material/styles';

import {ReactKeycloakProvider} from "@react-keycloak/web";
import my_keycloak from "./Keycloak"

import {HousesContextProvider} from "./context/HousesContext";
import AppUnAuth from "./AppUnAuth";

// const link = new WebSocketLink(
//     new SubscriptionClient("wss://buildon1.epu.ntua.gr/websocket-endpoint", {
//         reconnect: true
//     })
// );
//
// const client = new ApolloClient({
//     link: link,
//     cache: new InMemoryCache(),
// });

export const theme = createTheme({
    palette: {
        primary: {
            main: '#161F6D',
        },
        secondary: {
            main: '#00ABE1',
        },
        fancy: {
            main: `linear-gradient(to right, #161F6D, #00ABE1)`
        },
        fancyInverted: {
            main: `linear-gradient(to right, #00ABE1, #161F6D)`
        },
        middle: {
            main: '#0A6BAC'
        }
    },
    typography: {
        fontFamily: ['Poppins', 'Roboto',].join(','),
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    window.location.pathname === '/signup' ? (
        <ReactKeycloakProvider authClient={my_keycloak} initOptions={{}}>
            <ThemeProvider theme={theme}>
                <React.StrictMode>
                    {/*<HousesContextProvider>*/}
                        <AppUnAuth/>
                    {/*</HousesContextProvider>*/}
                </React.StrictMode>
            </ThemeProvider>
        </ReactKeycloakProvider>
    ) : (
        // <ReactKeycloakProvider authClient={my_keycloak} initOptions={{onLoad: 'login-required'}}>
        <ReactKeycloakProvider authClient={my_keycloak}>
            <ThemeProvider theme={theme}>
                <React.StrictMode>
                    <HousesContextProvider>
                        <App/>
                    </HousesContextProvider>
                </React.StrictMode>
            </ThemeProvider>
        </ReactKeycloakProvider>
    )
    // <ApolloProvider client={client}>

    // </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
