import React from 'react';
import {Card, CardActions, CardContent, CardHeader, Skeleton, Box, Grid} from "@mui/material";

const LoadingRoomCard = () => {
    return (
        <Card>
            <CardHeader
                avatar={<Skeleton animation="wave" variant="circular" width={40} height={40}/>}
                action={<Skeleton animation="wave" variant="circular" width={20} height={20} sx={{mt: 1, mr: 2}}/>}
                title={<Skeleton animation="wave" height={15} width="80%" style={{marginBottom: 6}}/>}
                subheader={<Skeleton animation="wave" height={15} width="60%"/>}
            />
            <CardContent>
                <Grid container alignContent={'center'} justifyContent={'center'} spacing={4} sx={{width: '100%'}}>
                    <Grid item xs={4} md={4} sx={{display: 'flex', flexDirection: 'column'}}>
                        <Skeleton sx={{margin: 'auto'}} animation="wave" variant="rounded" width={90} height={90}/>
                    </Grid>
                    <Grid item xs={4} md={4} sx={{display: 'flex', flexDirection: 'column'}}>
                        <Skeleton sx={{margin: 'auto'}} animation="wave" variant="rounded" width={90} height={90}/>
                    </Grid>
                    <Grid item xs={4} md={4} sx={{display: 'flex', flexDirection: 'column'}}>
                        <Skeleton sx={{margin: 'auto'}} animation="wave" variant="rounded" width={90} height={90}/>
                    </Grid>
                    <Grid item xs={4} md={4} sx={{display: 'flex', flexDirection: 'column'}}>
                        <Skeleton sx={{margin: 'auto'}} animation="wave" variant="rounded" width={90} height={90}/>
                    </Grid>
                    <Grid item xs={4} md={4}
                          sx={{display: 'flex', flexDirection: 'column'}}>
                        <Skeleton sx={{margin: 'auto'}} animation="wave" variant="rounded" width={90} height={90}/>
                    </Grid>
                </Grid>
                {/*<Grid container spacing={1} sx={{pb: 2}}>*/}
                {/*    <Grid item xs={6}>*/}
                {/*        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'65px'}*/}
                {/*                  sx={{borderRadius: '5px'}}/>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={6}>*/}
                {/*        <Skeleton animation="wave" variant="rectangular" width={'100%'} height={'65px'}*/}
                {/*                  sx={{borderRadius: '5px'}}/>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
                {/*<React.Fragment>*/}
                {/*    <Skeleton animation="wave" height={15} style={{marginBottom: 6}}/>*/}
                {/*    <Skeleton animation="wave" height={15} width="50%"/>*/}
                {/*</React.Fragment>*/}
            </CardContent>
            {/*<Box sx={{mt: 3}}>*/}
            {/*    <Skeleton animation="wave" height={20} width="60%" style={{marginBottom: 6, marginLeft: 15}}/>*/}
            {/*    <Skeleton animation="wave" height={20} width="60%" style={{marginBottom: 6, marginLeft: 15}}/>*/}
            {/*</Box>*/}
            <CardActions disableSpacing>
                <Skeleton animation="wave" variant="circular" width={40} height={40} sx={{ml: 'auto', mt: 1}}/>
            </CardActions>
        </Card>
    );
}

export default LoadingRoomCard;