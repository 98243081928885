import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {broadlinkAddForHouse} from "../../api_calls/api_calls_broadlinks";

import {
    Box, Button, Container, Divider, FormControl,
    Grid, TextField, Typography, Paper, Stack, Snackbar
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import PageTitle from "../../components/layout/PageTitle";
import LoadingFullPage from "../../components/layout/LoadingFullPage";
import BroadlinkListTable from "../../components/admin/BroadlinkListTable";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BroadlinkRegistration = () => {
    const {houseId} = useParams()

    useEffect(() => {
        document.title = 'Add Broadlink | Energy Home System'
    }, [])

    // Appliance information variables
    const [broadlinkName, setBroadlinkName] = useState('')
    const [broadlinkType, setBroadlinkType] = useState('')
    const [broadlinkMac, setBroadlinkMac] = useState('')
    const [broadlinkIp, setBroadlinkIp] = useState('')
    const [broadlinkPort, setBroadlinkPort] = useState('')

    // Appliance information error handling variables
    const [broadlinkNameError, setBroadlinkNameError] = useState(false)
    const [broadlinkTypeError, setBroadlinkTypeError] = useState(false)
    const [broadlinkMacError, setBroadlinkMacError] = useState(false)
    const [broadlinkIpError, setBroadlinkIpError] = useState(false)
    const [broadlinkPortError, setBroadlinkPortError] = useState(false)

    // Variable for backdrop
    const [backDrop, setBackDrop] = useState(false)

    // Variables for snackbar
    const [success, setSuccess] = useState(false)
    const [failure, setFailure] = useState(false)

    const handleSubmit = () => {
        setSuccess(false)
        setFailure(false)
        broadlinkName === '' ? setBroadlinkNameError(true) : setBroadlinkNameError(false)
        broadlinkType === '' ? setBroadlinkTypeError(true) : setBroadlinkTypeError(false)
        broadlinkMac === '' ? setBroadlinkMacError(true) : setBroadlinkMacError(false)
        broadlinkIp === '' ? setBroadlinkIpError(true) : setBroadlinkIpError(false)
        broadlinkPort === '' ? setBroadlinkPortError(true) : setBroadlinkPortError(false)

        if (broadlinkName && broadlinkType && broadlinkMac && broadlinkIp && broadlinkPort) {
            setBackDrop(true)
            broadlinkAddForHouse(broadlinkMac, broadlinkPort, broadlinkIp, broadlinkType, broadlinkName, houseId)
                .then(response => {
                    setBackDrop(false)
                    setSuccess(true)
                })
                .catch(err => {
                    setBackDrop(false)
                    setFailure(true)
                    console.log(err)
                })
        }
    }

    const handleCloseSnackbar = () => {
        setBroadlinkName('')
        setBroadlinkType('')
        setBroadlinkPort('')
        setBroadlinkMac('')
        setBroadlinkIp('')
        setFailure(false)
        setSuccess(false)
    }
    return (
        <>
            <PageTitle title="Add Broadlink"/>

            <Container sx={{mt: 5}} maxWidth={'xl'}>
                <Paper sx={{p: 3, mt: 5}}>
                    <Typography variant="h4" sx={{color: 'middle.main', pb: 0, pt: 3}}>Add Broadlink</Typography>
                    <Divider sx={{backgroundColor: 'middle.main', height: '3px', mb: 4}}/>
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <TextField fullWidth required id="outlined-basic" label="Broadlink name"
                                           variant="outlined"
                                           value={broadlinkName}
                                           placeholder="Insert broadlink name"
                                           error={broadlinkName === '' && broadlinkNameError}
                                           onChange={e => setBroadlinkName(e.target.value)}/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl required fullWidth error={broadlinkType === '' && broadlinkTypeError}>
                                    <TextField fullWidth required id="outlined-basic" label="Broadlink Dev type"
                                               variant="outlined"
                                               value={broadlinkType}
                                               type='number'
                                               inputProps={{min: 0}}
                                               placeholder="Insert broadlink dev type"
                                               error={broadlinkType === '' && broadlinkTypeError}
                                               onChange={e => setBroadlinkType(e.target.value)}/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField fullWidth required id="outlined-basic" label="Broadlink Mac Address"
                                           variant="outlined"
                                           placeholder="Broadlink Mac Address"
                                           value={broadlinkMac}
                                           error={broadlinkMac === '' && broadlinkMacError}
                                           onChange={e => setBroadlinkMac(e.target.value)}/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField fullWidth required id="outlined-basic" label="Broadlink IP"
                                           variant="outlined"
                                           placeholder="Broadlink IP"
                                           value={broadlinkIp}
                                           error={broadlinkIp === '' && broadlinkIpError}
                                           onChange={e => setBroadlinkIp(e.target.value)}/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField fullWidth required id="outlined-basic" label="Broadlink Port"
                                           variant="outlined"
                                           placeholder="Broadlink Port"
                                           value={broadlinkPort}
                                           type='number'
                                           inputProps={{min: 0}}
                                           error={broadlinkPort === '' && broadlinkPortError}
                                           onChange={e => setBroadlinkPort(Number(e.target.value))}/>
                            </Grid>
                        </Grid>
                    </form>
                    <Grid sx={{marginX: 'auto'}} item xs={12} md={3}>
                        <Box sx={{mt: 5}}>
                            <Button type="submit" fullWidth variant="contained" color="middle"
                                    onClick={handleSubmit}>
                                <Typography variant="sub" sx={{color: 'white'}}>ADD BROADLINK</Typography>
                            </Button>
                        </Box>
                        <Typography sx={{mt: 3}} variant={'subtitle2'} align={'center'}>HouseId: {houseId}</Typography>
                    </Grid>
                </Paper>
            </Container>

            <BroadlinkListTable setBroadlinkName={setBroadlinkName}
                                setBroadlinkType={setBroadlinkType}
                                setBroadlinkMac={setBroadlinkMac}
                                setBroadlinkIp={setBroadlinkIp}
                                setBroadlinkPort={setBroadlinkPort}
                                houseId={houseId}/>

            <LoadingFullPage backDrop={backDrop}/>
            <Stack spacing={2} sx={{width: '100%'}}>
                <Snackbar open={success} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="success">
                        The appliance has been successfully added!
                    </Alert>
                </Snackbar>
                <Snackbar open={failure} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="error">
                        Something went wrong! Please try again.
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    );
}


export default BroadlinkRegistration;