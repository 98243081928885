import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Loading({height, color}) {
  return (
    <Box sx={{ display: 'flex' }} height={height} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <CircularProgress sx={{color: color}} />
    </Box>
  );
}