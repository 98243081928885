import React, {useState, useEffect, useRef} from "react";
import {useParams} from "react-router-dom";
import {indigo} from "@mui/material/colors";

import {
    Divider, Typography, Container, Paper, Grid,
    InputLabel, Select, MenuItem, FormControl,
    Box, Button, Snackbar, Stack, Alert, AlertTitle,
    CircularProgress, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Modal,
} from "@mui/material";

import {styled} from '@mui/material/styles';
import {tableCellClasses} from '@mui/material/TableCell';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';

import {
    acLearning, acLearningModes, acExecuteFunction, acDeleteFunction, acDeleteLearntFunctions
} from "../../api_calls/api_calls_appliance";

import AirConditionLearningInstructions from "./AirConditionLearningInstructions";
import PageTitle from "../../components/layout/PageTitle";
import {acControllerGetEntity} from "../../api_calls/api_calls_broadlinks";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.middle.main, color: theme.palette.common.white,
    }, [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    }, // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const style = {
    position: 'relative', // justifyContent: 'center',
    display: 'flex', top: '30%', // left: '50%',3
    // transform: 'translate(-50%, -50%)',
    width: '40%', minWidth: 400, bgcolor: 'background.paper', borderRadius: '5px', boxShadow: 5, p: 4,
};

const AirConditionLearning = () => {
    const {id} = useParams();
    const [appliance, setAppliance] = useState({});

    useEffect(() => {
        acControllerGetEntity(id)
            .then(response => {
                document.title = `${response.name} Learning Mode | Energy Home System`
                setAppliance(response)
            })
            .catch(error => console.log(error))
    }, [])

    const [loading, setLoading] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    const [failMsg, setFailMsg] = useState(false)

    const [scenario, setScenario] = useState({})
    const [scenarioError, setScenarioError] = useState(false)

    const [learningModes, setLearningModes] = useState()
    const [emptyLearningModes, setEmptyLearningModes] = useState(false)

    const [loadingFunction, setLoadingFunction] = useState(false)
    const [modalFunction, setModalFunction] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)

    const functionChosen = useRef({})

    useEffect(() => {
        acLearningModes(id)
            .then(response => {
                setLearningModes(response)
            })
            .catch(error => console.log(error))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const handleCloseSnackbar = () => {
        setSuccessMsg(false)
    }

    const handleSubmit = e => {
        e.preventDefault()

        !scenario.func ? setScenarioError(true) : setScenarioError(false)

        if (Object.keys(scenario).length !== 0) {
            let isHeat = scenario.func === 'Heating' ? '1' : '0'
            setLoading(true)
            let ac = {
                isHeat: isHeat, accontrollerid: id, name: scenario.func === 'OFF' ? 'OFF' : '', temp: scenario.temp
            }

            acLearning(ac)
                .then(response => {
                    setSuccessMsg(true)
                    setLoading(false)

                    // Fetch learning modes again
                    acLearningModes(id)
                        .then(response => {
                            setLearningModes(response)
                        })
                        .catch(error => console.log(error))
                })
                .catch(error => {
                    setFailMsg(true)
                    setLoading(false)
                    console.log(error)
                })
        }
    }

    const handleExecuteFunction = learningMode => {
        // localStorage.setItem('chosenFunction', JSON.stringify(learningMode));
        functionChosen.current = learningMode
        let isHeat = learningMode.func === 'Heating' ? '1' : '0'
        let name = learningMode.func === 'OFF' ? 'OFF' : ''
        setLoadingFunction(true)
        let func = {
            isHeat, accontrollerid: id, name, temp: learningMode.temp
        }

        acExecuteFunction(func)
            .then(response => {
                setModalFunction(true)
                setLoadingFunction(false)
            })
            .catch(error => {
                setFailMsg(true) // TODO CHECK THIS
                setLoadingFunction(false)
            })
    }

    const handleDeleteFunction = () => {
        const learningMode = functionChosen.current
        setModalFunction(false)
        setLoadingFunction(false)
        let isHeat = learningMode.func === 'Heating' ? '1' : '0'
        let name = learningMode.func === 'OFF' ? 'OFF' : ''
        let chosenFunction = {
            isHeat, accontrollerid: id, name, temp: learningMode.temp
        }
        acDeleteFunction(chosenFunction)
            .then(response => {
                // Receive again the learning modes
                setLearningModes(response)
            })
            .catch(error => {
                console.log(error) // TODO Display error message?
            })
    }

    const openDeleteModal = () => {
        setModalDelete(true)
    }

    const handleDeleteAllFunctions = () => {
        acDeleteLearntFunctions(id)
            .then(response => {
                // Receive again the learning modes
                setLearningModes(response)
                setModalDelete(false)
            })
            .catch(error => {
                setModalDelete(false)
                setFailMsg(true)
            }) // TODO CHECK THIS
    }

    useEffect(() => {
        let counter = 0
        if (learningModes?.length > 0) {
            for (let i = 0; i < learningModes.length; i++) {
                if (learningModes[i].learnt === true) {
                    counter++
                }
            }
            counter > 0 ? setEmptyLearningModes(false) : setEmptyLearningModes(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [learningModes])

    return (<React.Fragment>
        <PageTitle title={appliance.name + ' Learning Mode'}/>
        <Modal
            open={modalFunction}
            onClose={() => setModalFunction(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Container sx={style}>
                <Grid container spacing={0} direction="column" alignItems="center"
                      justifyContent="center">
                    <Typography align={'center'} variant={'h5'}>
                        Check if your A/C executed the correct function.
                    </Typography>
                    <Typography mt={5} variant={'h6'}>
                        Was the correct function executed?
                    </Typography>
                    <Stack direction="row" spacing={2} mt={3}>
                        <Button variant="contained" color="success"
                                onClick={() => setModalFunction(false)}>YES</Button>
                        <Button variant="outlined" color="error"
                                onClick={() => handleDeleteFunction()}>NO</Button>
                    </Stack>
                </Grid>
            </Container>
        </Modal>
        <Modal
            open={modalDelete}
            onClose={() => setModalDelete(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Container sx={style}>
                <Grid container spacing={0} direction="column" alignItems="center"
                      justifyContent="center">
                    <Typography align={'center'} variant={'h5'}>
                        Pressing "YES" will delete all functions known to the device and it will have to be learnt
                        again.
                    </Typography>
                    <Typography align={'center'} mt={5} variant={'h6'}>
                        Are you sure that you want to perform this action?
                    </Typography>
                    <Stack direction="row" spacing={2} mt={3}>
                        <Button variant="contained" color="success"
                                onClick={handleDeleteAllFunctions}>YES</Button>
                        <Button variant="outlined" color="error"
                                onClick={() => setModalDelete(false)}>NO</Button>
                    </Stack>
                </Grid>
            </Container>
        </Modal>
        <Container>
            <Paper sx={{p: 3, mt: 5}}>
                <Box display={'flex'}>
                    <Typography variant="h4" sx={{color: 'middle.main', pb: 0, pt: 3}}>
                        Insert functions
                    </Typography>
                    <Button onClick={openDeleteModal} variant="contained" color="error" size={'medium'}
                            sx={{ml: 'auto', my: 'auto'}}>
                        CLEAR
                    </Button>
                </Box>
                <Divider sx={{backgroundColor: 'middle.main', height: '3px', mb: 4}}/>
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container spacing={2} display={'flex'} justifyContent={'center'}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth required error={!scenario.func && scenarioError}>
                                <InputLabel id="demo-simple-select-label">Choose functionality</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={(scenario.id || scenario.id === 0) ? scenario.id : ''}
                                    label="Επιλέξτε λειτουργία"
                                    onChange={e => setScenario(learningModes[parseInt(e.target.value)])}
                                >
                                    {learningModes ? learningModes.map(learningMode => {
                                        return (<MenuItem value={learningMode.id} key={learningMode.id}
                                                          disabled={learningMode.learnt}>
                                            {learningMode.func !== 'OFF' ? learningMode.func + ' at ' + learningMode.temp + '°C' : learningMode.func}
                                            {learningMode.learnt ? <React.Fragment>
                                                <CheckCircleIcon sx={{color: 'green', my: 'auto'}}/>
                                                <Typography
                                                    sx={{color: 'green', fontWeight: 'bold', ml: 'auto'}}>
                                                    Learning completed
                                                </Typography>
                                            </React.Fragment> : null}
                                        </MenuItem>)
                                    }) : null}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {Object.keys(scenario).length !== 0 && <AirConditionLearningInstructions scenario={scenario}/>}

                    <Grid sx={{marginX: 'auto'}} item xs={12} md={2} display={'flex'} justifyContent={'center'}>
                        <Box sx={{mt: 5, mb: 2}}>
                            <Button type="submit" fullWidth variant="contained" color="middle">
                                <Typography variant="small" sx={{color: 'white'}}>LEARN</Typography>
                            </Button>
                        </Box>
                    </Grid>
                    {loading &&
                        <Grid sx={{marginX: 'auto'}} item xs={12} md={2} display={'flex'} justifyContent={'center'}>
                            <Box>
                                <Container sx={{marginX: 'auto'}}><CircularProgress/></Container>
                            </Box>
                        </Grid>}
                </form>

                <Typography variant="h4" sx={{color: 'middle.main', pb: 0, pt: 3}}>
                    Available Functions
                </Typography>
                <Divider sx={{backgroundColor: 'middle.main', height: '3px', mb: 4}}/>
                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left"><Typography
                                    variant={'h6'}>Function</Typography></StyledTableCell>
                                <StyledTableCell align="right" colSpan={3}>
                                    {loadingFunction ? <CircularProgress size={24} sx={{color: 'white'}}/> : null}
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {learningModes && learningModes.map((learningMode) => (learningMode.learnt === true &&
                                <StyledTableRow key={learningMode.id}>
                                    <StyledTableCell component="th" scope="row">
                                        <Typography variant={'body1'} fontWeight={500}>
                                            {learningMode.func !== 'OFF' ? learningMode.func + ' at ' + learningMode.temp + '°C' : learningMode.func}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Button variant="contained"
                                                onClick={() => handleExecuteFunction(learningMode)}
                                                sx={{
                                                    backgroundColor: indigo[500],
                                                    '&:hover': {backgroundColor: [indigo[700]]}
                                                }}
                                                startIcon={<SettingsRemoteIcon/>}>
                                            <Typography variant='small'>EXECUTE</Typography>
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow>))}
                        </TableBody>
                    </Table>
                    {emptyLearningModes === true ? <Alert sx={{mt: 2, width: '100%'}} severity="error">
                        <AlertTitle>No scenarios have been learnt!</AlertTitle>
                        Use the learning mode to proceed.
                    </Alert> : void (0)}
                </TableContainer>
            </Paper>
        </Container>
        <Stack spacing={2} sx={{width: '100%'}}>
            <Snackbar open={successMsg} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    The signal has been successfully recorded!
                </Alert>
            </Snackbar>
            <Snackbar open={failMsg} autoHideDuration={3000} onClose={() => setFailMsg(false)}>
                <Alert onClose={() => setFailMsg(false)} severity="error">
                    Something went wrong! Please try again.
                </Alert>
            </Snackbar>
        </Stack>
    </React.Fragment>);
}

export default AirConditionLearning;