import Keycloak from "keycloak-js";

// Production configuration
// const my_keycloak = new Keycloak({
//     "realm": "EnergyHomeSystem",
//     "url": "https://buildon.epu.ntua.gr/auth",
//     "clientId": "EnergyHomeSystem_prod"
// })

// Development configuration
const my_keycloak = new Keycloak({
    "realm": "EnergyHomeSystem",
    "url": "https://keycloak.buildon.epu.ntua.gr/",
    "clientId": "EnergyHomeSystem_dev"
})



export default my_keycloak