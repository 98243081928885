import {Grid, Typography, Box, Container} from '@mui/material';

function HomepageLastSection(props) {
    return (
        <Box
            sx={{
                flexGrow: 1,
                width: '100%',
                pl: 5,
                backgroundColor: 'background.paper', // Adjust background color as needed
            }}
            className={'fancyBackground'}
        >
            <Grid container>
                <Grid item xs={12} md={5} sx={{display: 'flex', alignItems: 'center', padding: 3}}>
                    <Container>
                        <Box>
                            <Typography variant="h2" gutterBottom color={'white'} fontWeight={'500'}>
                                Optimizing Your Home, Simplifying Your Life
                            </Typography>
                            <Typography variant="body2" paragraph color={'white'}>
                                A smart home app designed to save energy, reduce costs, and enhance your home
                                experience. Take full control of your home's energy efficiency and device management,
                                all from the
                                convenience of your desktop, tablet, or smartphone.
                            </Typography>
                        </Box>
                    </Container>
                </Grid>

                <Grid item xs={12} md={7} sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Box
                        sx={{
                            maxHeight: '500px', // Adjust max height as needed
                            overflow: 'hidden', // Hide overflow to avoid any parts going outside the box
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src="/images/homepage_bottom.png"
                            alt="Descriptive text"
                            style={{
                                height: 'auto',
                                maxWidth: '100%',
                                objectFit: 'contain',
                                display: 'block' // Ensures the image does not have any unwanted margins/padding
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default HomepageLastSection;
