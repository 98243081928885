import React, {useState, useEffect} from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {getAllHouses, deleteHouseEntity} from '../../api_calls/api_calls_houses';
import {useRefreshHouses} from '../../hooks/useRefreshHouses';
import {Container, Grid, Snackbar, Stack} from '@mui/material';
import Alert from '@mui/material/Alert';
import PageTitle from '../../components/layout/PageTitle';
import LoadingHouseCard from '../../components/houses/LoadingHouseCard';
import ZeroHouses from '../../components/houses/ZeroHouses';
import FloatingActionButton from '../../components/layout/FloatingActionButton';
import HouseCard from '../../components/houses/HouseCard';
import LoadingFullPage from '../../components/layout/LoadingFullPage';

const MyHouses = () => {
    useEffect(() => {
        document.title = 'Houses | Energy Home System';
    }, []);

    const {keycloak, initialized} = useKeycloak();
    const {refreshHouses} = useRefreshHouses();
    const [allowed, setAllowed] = useState(false);
    const [houses, setHouses] = useState([]);
    const [zeroHouses, setZeroHouses] = useState(false);
    const [loading, setLoading] = useState(false);
    const [backDrop, setBackDrop] = useState(false);
    const [notification, setNotification] = useState({open: false, message: '', severity: 'success'});

    useEffect(() => {
        if (initialized) {
            if (!keycloak.authenticated) {
                keycloak.login();
            } else {
                setAllowed(true);
            }
        }
    }, [keycloak, initialized]);

    const handleDeleteHouse = houseId => {
        setBackDrop(true);
        deleteHouseEntity(houseId)
            .then(() => {
                refreshHouses();
                setNotification({
                    open: true,
                    message: 'The house has been successfully deleted!',
                    severity: 'success'
                });
                getAllHouses()
                    .then(response => {
                        if (!response || response.length === 0) {
                            setZeroHouses(true);
                        } else {
                            setHouses(response);
                        }
                    })
                    .catch(() => {
                        setNotification({
                            open: true,
                            message: 'Something went wrong! Please try again.',
                            severity: 'error'
                        });
                    })
                    .finally(() => {
                        setBackDrop(false);
                    });
            })
            .catch(error => {
                setNotification({open: true, message: 'Something went wrong! Please try again.', severity: 'error'});
                setBackDrop(false);
            });
    };


    useEffect(() => {
        setLoading(true);
        if (initialized === true) {
            getAllHouses()
                .then(response => {
                    if (!response || response.length === 0) {
                        setZeroHouses(true);
                    } else {
                        setHouses(response);
                    }
                })
                .catch(error => {
                    setZeroHouses(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [initialized]);

    useEffect(() => {
        refreshHouses();
    }, [houses]);

    const handleNotificationClose = () => {
        setNotification({...notification, open: false});
    };

    return (
        <>
            <PageTitle title="Houses"/>
            {allowed && (
                <>
                    {loading && (
                        <Container maxWidth="xl">
                            <Grid container rowSpacing={2} spacing={2} sx={{pt: 3}}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <LoadingHouseCard/>
                                </Grid>
                            </Grid>
                        </Container>
                    )}
                    {zeroHouses && <ZeroHouses/>}
                    {!zeroHouses && !loading && (
                        <Container maxWidth="xl" sx={{my: 5}}>
                            <Grid container rowSpacing={2} spacing={2} sx={{mt: 3, px: {xs: 1, md: 5}}}>
                                {houses.map(house => (
                                    <Grid item xs={12} md={6} lg={6} key={house.id}>
                                        <HouseCard house={house} deleteHouse={handleDeleteHouse}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </Container>
                    )}
                    <FloatingActionButton link="/add/house" tooltip="Add house"/>
                    <LoadingFullPage backDrop={backDrop} setBackDrop={setBackDrop}/>
                    <Stack spacing={2} sx={{width: '100%'}}>
                        <Snackbar
                            open={notification.open}
                            onClose={handleNotificationClose}
                            autoHideDuration={3000}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        >
                            <Alert
                                variant="filled"
                                onClose={handleNotificationClose}
                                severity={notification.severity}
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    </Stack>
                </>
            )}
        </>
    );
};

export default MyHouses;
