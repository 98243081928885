import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';

export const functionalityListHomepage = [
    {
        'id': 'remote_control',
        'title': 'Control the devices remotely',
        'description': 'User-friendly, smart controller interfaces for your devices',
        'icon': <SettingsRemoteIcon style={{color: 'white', fontSize: '60px'}}/>,
        'image': '/images/control_devices.jpg',
    },
    {
        'id': 'scheduling',
        'title': "Schedule devices' operation",
        'description': "Schedule your devices’ operation and enhance their functionality. Save energy!",
        'icon': <ScheduleSendIcon style={{color: 'white', fontSize: '60px'}}/>,
        'image': '/images/schedule_devices.jpg',
    },
    {
        'id': 'appliances',
        'title': 'Multiple devices',
        'description': "Connect a variety of devices and control them from a unified interface. Energy and performance data is available through a customised dashboard.",
        'icon': <ScatterPlotIcon style={{color: 'white', fontSize: '60px'}}/>,
        'image': '/images/multiple_devices.jpg',
    },
]