import {useContext} from "react";
import {HousesContext} from "../context/HousesContext";

const useHousesContext = () => {
    const context = useContext(HousesContext)

    if (!context) {
        throw Error('useHousesContext must be used inside an housesContext!')
    }
    return context
}

export default useHousesContext