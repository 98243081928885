export const features = [
    {
        icon: <img src="/images/homepage_feature1.png" alt="Devices" style={{width: 200, height: 200}} />,
        title: 'Control your devices remotely',
        description: 'Experience intuitive and intelligent control interfaces to effortlessly manage all your devices.',
    },
    {
        icon: <img src="/images/homepage_feature2.png" alt="Schedule" style={{width: 200, height: 200}} />,
        title: 'Schedule devices\' operation',
        description: 'Schedule your devices\' operation and enhance their functionality, while saving energy.',
    },
    {
        icon: <img src="/images/homepage_feature3.png" alt="Settings" style={{width: 200, height: 200}} />,
        title: 'Manage multiple devices',
        description: 'Connect multiple devices and control them through a single interface with custom dashboards.',
    },
];
